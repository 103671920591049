const es = {
  general: {
    download: "Descargar",
    images: 'Imagenes',
    dataDowload: 'Descarga de datos',
    dataGeneralDowload: 'Datos del cuestionario',
    evolutionChart: 'Curva de Evolución',
    downloadCsv: 'Descargar archivo CSV',
    generateFile: 'Generar archivo',
    noOptions: 'No options',
    dateLocale: 'es-ES',
    dateFormat: 'dd-MM-yyyy',
    timeFormat: 'HH:mm',
    dateTimeFormat: 'dd-MM-yyyy hh:mm aa',
    dateFormatGeneral: 'yyyy-MM-dd HH:mm:ss',
    dataNotFound: 'N/D',
    dateOfResponse: 'Fecha de respuesta',
    search: 'Buscar en',
    selectDate: 'Seleccione',
    secondsUnit: 's',
    angleUnit: '°',
    stepUnit: 'Pasos',
    mostRecent: 'Lo más reciente es',
    name: 'Nombre',
    notFound: 'Información no encontrada',
    actions: 'Acciones',
    copyright: 'IDPSanté - © Copyright todos los derechos reservados',
    notFound404: 'La página que ha solicitado no se ha encontrado...',
    notFound401: 'Por razones de seguridad, vuelva a conectarse',
    error404: 'Página no encontrada',
    error401: 'La sesión ha expirado',
    moreInfo: 'Ver más información',
    welcome: 'Bienvenido  ',
    question: 'Pregunta',
    lastUpdate: 'Última actualización',
    sendEmail: 'Enviar un correo electrónico',
    total: 'Total',
    right: 'Derecha',
    left: 'Izquierda',
    fragilityFried: 'Escala de Fragilidad Fried',
    fragilityIndicators: 'Indicadores de fragilidad',
    bilanMarche: 'Marcha',
    physiologique: 'Fisiológico',
    psychologique: 'Psicológico',
    placeholderMedical: 'Búsqueda por nombre médico',
    preview: 'Vista previa',
    score: 'Puntuación',
    response: 'Respuesta',
    patientMenu: {
      name: 'Nombre Completo',
      lastName: 'Apellidos',
      birthday: 'Fecha de nacimiento',
      sex: 'Género',
      address: 'Dirección',
      phone: 'Teléfono',
      score: 'Score de fragilidad',
      dateEnd: 'Fecha del último smartpredict',
      medical_staff: 'Personal médico',
      value: 'Respuesta a la pregunta',
    },
    fragilityScore: {
      warning: 'Pré-frágil',
      danger: 'Frágil',
      good: 'Robusto',
      noScore: 'Sin Score',
    },
    gender: {
      female: 'Femenino',
      male: 'Masculino',
      gender: 'Género',
    },
  },
  components: {
    modal: {
      value: 'Valor(*)',
      valueOptional: 'Valor',
      key: 'Clave(*)',
      children: 'Niños',
    },
    refresh: 'Actualización',
    warningAtention: 'Atención',
    warningDelete:
      'Esta acción borrará la configuración de los siguientes pasos, ¿está seguro de que quiere continuar?',
    deleteMessage: '¿Está seguro de que quiere eliminar este elemento?',
    uploadImage: 'Subir imagen',
    viewImage: 'Ver imagen',
    sendMyself: 'Enviar un correo a mi dirección de correo electrónico',
    status: 'Estatus',
    changeStatus: 'El estado se ha cambiado con éxito',
    errorStatus: 'El estado no ha podido ser modificado',
    required: 'Requerido',
    noRequired: 'No es necesario',
    ok: 'OK',
    pickerPlaceholder: 'Seleccione',
    cancel: 'Cancelar',
    send: 'Enviar',
    delete: 'Borrar',
    increment: 'Añadir',
    alertEdit: 'Registro modificado con éxito',
    alert: 'Registro creado con éxito',
    alertDelete: 'Registro eliminado con éxito',
    deleteError: 'Ocurrió un error en la solicitud',
    update: 'Actualización',
    createItem: 'Añadir un registro',
    editItem: 'Actualizar el registro',
    deleteItem: '¿Desea eliminar este registro?',
    main: 'Página principal',
    backLogin: 'Ir al inicio de sesión',
    homeCard: 'Pacientes',
    back: 'Volver',
    next: 'Siguiente',
    success: 'Solicitud procesada',
    yes: 'Sí',
    profileIcon: {
      myProfile: 'Mi perfil',
      logout: 'Cerrar la sesión',
    },
    traductions: {
      missingTraductionTitle: '¡Campos aún por traducir!',
      missingTraductiontext:
        'Todavía hay algunos campos que no están traducidos. ¿Está seguro de que quiere enviar esta información? ',
    },
  },
  myProfile: {
    title: 'Mi perfil',
    myInfo: 'Mi información',
    dataUpdated: 'Datos actualizados',
    emailDirection: 'Dirección de correo electrónico:',
    editInformation: 'Cambiar de usuario',
    resetPasswordTitle: 'Restablecer contraseña',
    editProfileTitle: 'Cambiar mis datos personales',
    resetPassword: 'Confirmar la nueva contraseña',
    inputs: {
      name: 'Nombre',
      nameCenter: 'Nombre del centro médico',
      lastname: 'Apellido',
      phone: 'Número de teléfono',
      birth: 'Fecha de nacimiento',
      birthCenter: 'Fecha de incorporación',
    },
  },
  cruds: {
    healthCondition: {
      noData: 'Información no encontrada',
      addTranslate: 'Añadir',
      editTranslate: 'Editar',
      listItem: 'Lista de subcategorías',
      missingItem: 'Traducciones en curso',
      create: 'Nuevo estado de salud',
      edit: 'Editar el estado de salud',
      created: 'Se ha creado el estado de salud',
      edited: 'Se ha modificado el estado de salud',
      detail: 'Detalles del estado de salud',
      show: 'Detalles del estado de salud',
      generalInformation: 'Información general',
      translate: 'Traducir',
      csv: 'Buscar un CSV',
      return: 'Volver a la lista de códigos sanitarios',
      category: 'Nombre de la categoría principal *',
      subCategory: 'Subcategorías *',
      instructions:
        'Puedes añadir tantas subcategorías como necesites. Separe cada subcategoría con una coma o simplemente arrastre y suelte un archivo CSV aquí',
      translateInstruction:
        'Puede escribir cada traducción en su propia línea, o si lo prefiere puede arrastrar un archivo CSV al cuadro de traducciones',
    },
    smartTest: {
      statusSelector: 'Selector de estado',
      indicator: '¿Este paso genera un indicador?',
      dataSourceId: 'ID de la fuente de datos',
      enum: {
        create: 'Crear',
        update: 'Actualización',
        recordless: 'No hay registro',
      },
      goToQuestions: 'Ir a la sección de preguntas',
      questionarie: 'Cuestionario',
      smartQuestionnaire: 'Cuestionario SMART',
      kinvent_grip: 'K-Physio Grip',
      kinvent_gait: 'K-Physio Gait',
      kinvent_stance: 'K-Physio Stance',
      kinvent_singlemove: 'K-Physio SingleMove',
      kinvent_open: 'K-Physio Open',
      aminogram_test: "Prueba de impedancia",
      kinvent_gait_no_cal: "Kinvent gait no cal",
      kinvent_gait_three_no_cal: "Kinvent gait three no cal",
      xsens: 'X-sens',
      smartTest: 'Prueba SMART',
      kforceStrength: 'Resistencia de la fuerza Kforce',
      kforceBalance: 'Balance Kforce ',
      teleconsultation: 'Teleconsulta',
      button: 'Añadir una nueva prueba',
      block: 'Bloque',
      tableheader: {
        title: 'Título',
        description: 'Descripción',
        typeTest: 'Tipo de prueba',
        numberSensors: 'N Sensores',
        typeProvider: 'Tipo de proveedor',
        status: 'Estatus',
        actions: 'Acciones',
        position: 'Posición',
      },
      associateStaffType: {
        help: 'Seleccione las especializaciones que pueden aparecer en el smartTest, de lo contrario todas las especializaciones se verán en el SmartTest',
        associate: 'Asociación del Personal',
        returnList: 'Volver a la lista',
        success: 'Asociado con éxito',
        ok: 'La asociación de personal se llevó a cabo',
        smartTest: 'SmartTest',
      },
      form: {
        actionSelect: 'Selección de la acción',
        name: 'Título de SmartTest',
        updateProvider: '¿Actualizar al proveedor?',
        idpApp: 'IDP-APP',
        idpSmart: 'IDP-SMART',
        providers: 'Proveedores',
        sensorsLabel: 'Número de sensores a utilizar',
        sensors: 'Número de sensores',
        description: 'Descripción de SmartTest',
        blockType: 'Tipo de bloque',
        questionarieSelect: 'Elige un cuestionario',
        smartTestSelect: 'Elige un cuestionario SmartTest',
        instructions: 'Instrucciones del bloque',
        instructionTest: 'Instrucciones para el cuestionario SmartTest',
        instructionSensors: 'Instrucciones para los sensores',
        thirdOption: 'Tiempo de respuesta',
        icon: 'Icono de test',
        position: 'Posición del pedido',
      },
      steps: {
        stepOne: { step: 'Paso 1', label: 'Información general' },
        stepTwo: { step: 'Paso 2', label: 'Configuración del proceso' },
        stepThree: { step: 'Paso 3', label: 'Configuración del idioma' },
        questions: {
          stepOne: { step: 'Paso 1', label: 'Cuestiones editoriales' },
          stepTwo: { step: 'Paso 2', label: 'Configuración del idioma' },
        },
      },
      create: {
        indicator: 'Nuevo SmartTest',
        title: 'Crear un nuevo SmartTest',
        subtitle: 'Nuevo SmartTest',
        newBlock: 'Añadir un bloque',
        created: 'Los SmartTest se ha creado con éxito',
        return: 'Volver a la lista de SmartTests',
      },
      edit: {
        viewSavedImage: 'Ver imagen guardada',
        viewImageInstructions: 'Ver imagen para las instrucciones',
        viewImageSensors: 'Ver imagen de los sensores',
        instructional_picture: 'Ver imagen para las instrucciones',
        data_sensors: 'Ver imagen de los sensores',
        indicator: 'Modificar un SmartTest',
        title: 'Modificar el SmarTest actual',
        subtitle: 'Modificación de un SmartTest',
        created: 'El smartTest se modificó con éxito',
        return: 'Volver a la lista de SmartTest',
      },
      questions: {
        numberOfQuestions: 'Número de preguntas',
        indicator: 'Pasos',
        title: 'Lista de pasos',
        edit: {
          title: 'Editar el cuestionario',
          return: 'Volver a la lista de pasos',
        },
        form: {
          nameQuestionLabel: 'Título de la pregunta',
          typeofQuestionLabel: 'Tipo de pregunta',
          option: 'Opción',
        },
      },
      selectionSimple: 'Selección individual',
      selectionMultiple: 'Selección múltiple',
      open: 'Pregunta libre',
    },
    patients: {
      anonimusFieldsDownload: 'Descargar archivos anonimizados',
      return: 'Volver a la lista de pacientes',
      button: 'Añadir un paciente',
      steps: {
        stepOne: 'Paso 1',
        stepTwo: 'Paso 2',
      },
      labels: {
        stepOne: 'Información para el paciente',
        stepTwo: 'Doctor y personal médico',
      },
      create: {
        indicator: 'Nuevo paciente',
        title: 'Crear un nuevo paciente',
        created: 'El paciente ha sido creado con éxito',
      },
      edit: {
        indicator: 'Modificar el paciente',
        title: 'Modificar un paciente',
        created: 'El paciente ha sido editado con éxito',
      },
    },
    medicalCenters: {
      return: 'Volver a la lista de centros',
      htmlName: 'Nombre del archivo html',
      identifierss: 'Identificadores',
      tableheader: {
        fullName: 'Nombre',
        type: 'Tipo',
        email: 'Correo electrónico',
        status: 'Estatus',
        address: 'Dirección',
      },
      informationList: {
        info1: {
          title:
            'El HTML debe corresponder únicamente al cuerpo del documento, la cabecera y el footer serán agregados automáticamente.',
        },
        info2: {
          title:
            'Dentro del HTML puede incluir las siguientes etiquetas, las cuales serán reemplazadas por la información guardada en nuestra plataforma:',
          childs: {
            c1: 'nombre completo del paciente',
            c2: 'la dirección del paciente',
            c3: 'número de identificación del paciente',
            c4: 'fecha de vencimiento del documento de identificación del paciente',
            c5: 'la ciudad del centro médico',
            c6: 'la fecha en la que se firma dicho documento',
            c7: 'la firma del paciente',
          },
        },
        info3: {
          title: 'El archivo HTML debe tener un nombre',
        },
      },
      createButton: 'Nuevo Centro de IDP',
      show: {
        indicator: 'Ver detalle',
        titlePage: 'Ver detalles del Centro de IDP',
        informationTitle: 'Información sobre el centro ',
        personalTitle: 'Personal médico asociado',
        contactTitle: 'Personas de contacto',
        noContacts: 'Sin contactos asociados',
        address: 'Dirección del centro',
        back: 'Volver',
      },
      create: {
        titlePage: 'Crear un nuevo centro',
        addressSubtitle: 'Añadir la dirección de la planta',
        addressTitle: 'Dirección del centro',
        indicator: 'Nuevo centro',
        created: 'El Centro se creó con éxito',
        licenseTitle: 'Añadir número de licencia',
        licenseSubtitle: 'Número de licencia',
        informationCenter: 'Añadir información sobre el Centro',
        consentDocument: 'Documento de consentimento',
      },
      edit: {
        titlePage: 'Modificación de un centro',
        addressSubtitle: 'Actualización de un centro médico',
        addressTitle: 'Dirección del centro',
        indicator: 'Actualización de un centro médico',
        created: 'El centro se ha sido modificado con éxito',
        informationCenter: 'Actualización de la información del centro',
      },
      form: {
        name: 'Nombre del centro',
        email: 'Dirección de correo electrónico',
        phone: 'Número de teléfono',
        country: 'País',
        city: 'Ciudad',
        address: 'Dirección postal',
        typeCenter: 'Tipo de centro médico',
        license: 'Número de licencia',
        updateLicense: 'Actualización del número de licencia',
        nameOfTheConsentDocument: 'Nombre del documento de consentimiento',
        descriptionOfTheConsentDocument:
          'Descripción del documento de consentimiento',
        invalidFormat: 'El formato no es valido',
        confidentialDocument: 'Documento confidencial',
      },
      contacts: {
        button: 'Añadir un contacto',
        title: 'Añadir información de contacto',
        subtitle: 'Lista de contactos asociados',
        form: {
          name: 'Nombre',
          lastname: 'Apellido',
          email: 'Correo electrónico',
          phone: 'Teléfono',
          fullName: 'Nombre completo',
          speciality: 'Especialidad',
        },
      },
      sensors: {
        title: 'Configuración de un dispositivo SmartPredict',
        detail: 'Dispositivo SmartPredict',
        kit: 'Kit',
        subtitle: 'MAC adress de los sensores del kit',
        button: 'Añadir un kit de sensores',
        form: {
          sensor: 'Sensor',
        },
      },
      biody: {
        title: "Configuración de un dispositivo Biody",
        biody: "Biody",
        titleShow: "Dispositivo Biody",
        button: "Añadir un dispositivo",
        form: {
          id: "Dispositivo",
          license: "Licencia"
        }
      }
    },
    users: {
      return: 'Volver a la lista de usuarios',
      tableheader: {
        fullName: 'Nombre completo',
        rol: 'Posición',
        age: 'Edad',
        email: 'Dirección de correo electrónico',
        verified: 'Verificado',
      },
      crateButton: 'Crear un nuevo usuario',
      create: {
        titlePage: 'Crear un usuario',
        indicator: 'Nuevo usuario',
        created: 'El usuario ha sido creado con éxito',
        userInfoTitle: 'Informar al usuario',
        userPasswordTitle: 'Crear una contraseña',
      },
      edit: {
        titlePage: 'Actualizar un usuario',
        indicator: 'Cambiar de usuario',
        created: 'El usuario ha sido modificado con éxito',
      },

      inputs: {
        name: 'Nombre(*)',
        lastname: 'Apellido(*)',
        phone: 'Número de teléfono(*)',
        birth: 'Fecha de nacimiento(*)',
        email: 'Correo electrónico(*)',
        locale: 'Idioma(*)',
      },
    },

    profiles: {
      return: 'Volver a la lista de perfiles',
      list: {
        button: 'Nuevo perfil',
      },
      create: {
        indicator: 'Nuevo perfil',
        title: 'Nuevo indicador de perfil',
        step: {
          first: 'Paso 1',
          second: 'Paso 2',
          third: 'Paso 3',
        },
        stepLabel: {
          first: 'Información general',
          second: 'Configuración del perfil',
          third: 'Configuración del idioma',
        },
        nameLabel: 'Nombre del perfil(*)',
        providerLabel: 'Proveedor(*)',
        descriptionLabel: 'Descripción del perfil(*)',
        indicatorsSelection:
          'Seleccione los indicadores que desea añadir a este perfil',
        indicators: 'Indicadores',
        indicatorsTitle: 'Indicadores de enlace',
        translateTitle: 'Por favor, traduzca los siguientes textos',
        created: 'El perfil ha sido creado con éxito',
        translateLabel: 'TEXTO ORIGINAL',
        noImage: 'Sin imagen de instrucción',
        textToTranslate: 'TEXTO A TRADUCIR',
        name: 'Nombre',
        description: 'Descripción',
      },
      edit: {
        indicator: 'Actualización del perfil',
        title: 'cambiar el perfil del indicador',
        edited: 'El perfil se ha actualizado con éxito',
      },
    },
    indicators: {
      return: 'Volver a la lista de indicadores',
      list: {
        button: 'Nuevo indicador',
      },
      create: {
        indicator: 'Nuevo indicador',
        title: 'Crear un nuevo indicador',
        step: {
          first: 'Paso 1',
          second: 'Paso 2',
          third: 'Paso 3',
        },
        stepLabel: {
          first: 'Información general',
          second: 'Notas específicas',
          third: 'Configuración del idioma',
        },
        nameLabel: 'Nombre del indicador(*)',
        descriptionLabel: 'Descripción del indicador(*)',
        dataSourceLabel: 'Fuente de datos(*)',
        frequencieLabel: 'Frecuencia(*)',
        recomendationLabel: 'Recomendación',
        interpretationLabel: 'Interpretación(*)',
        interpretation: 'Interpretación',
        unitLabel: 'Tipo de unidad(*)',
        valueLabel: 'Valor',
        acronymLabel: 'Acrónimo(*)',
        type: 'Tipo de datos',
        indicatorsSelection:
          'Seleccione los indicadores que desea añadir a este perfil,',
        indicators: 'Indicadores',
        indicatorsTitle: 'título de los indicadores',
        translateTitle: 'Por favor, traduzca los siguientes textos',
        created: 'El indicador ha sido creado con éxito',
        translateLabel: 'TEXTO ORIGINAL',
        textToTranslate: 'TEXTO A TRADUCIR',
        name: 'Nombre del indicador',
        description: 'Descripción del indicador',
      },
      edit: {
        indicator: 'Actualizar un indicador',
        title: 'Actualización de un indicador',
        edited: 'El indicador se ha actualizado con éxito',
      },
      type: {
        text: 'Texto',
        number: 'Número',
        image: 'Imagen',
        static: 'Estática',
        boolean: 'Booleano',
      },
      annotation: {
        button: 'Añadir una recomendación',
      },
    },
    questionaries: {
      return: 'Volver a la lista de encuestas',
      changeButtom: 'Cambiar ',
      changeStatus: '¿Quieres cambiar el estado?',
      selectionSimple: 'Selección simple',
      selectionMultiple: 'Selección múltiple',
      numeric: 'Numérico',
      label: 'Etiqueta',
      option: 'Opción',
      score: 'Puntuación',
      list: {
        button: 'Nuevo cuestionario',
        status: 'Estado',
        questionarie: 'Cuestionario',
      },
      create: {
        indicator: 'Nueva cuestionario',
        title: 'Nuevo cuestionario',
        addOption: 'Añadir una pregunta',
        step: {
          first: 'Paso 1',
          second: 'Paso 2',
          third: 'Paso 3',
        },
        stepLabel: {
          first: 'Información general',
          second: 'Preguntas',
          third: 'Configuración de idioma',
        },
        nameLabel: 'Nombre del cuestionario(*)',
        descriptionLabel: 'Instrucciones para el cuestionario(*)',
        nameQuestionLabel: 'Título de la pregunta',
        typeofQuestionLabel: 'Tipo de pregunta',
        name: 'Nombre del cuestionario',
        description: 'Descripción',
        instructions: 'Instrucciones para el concurso',
        created: 'Cuestionario creado con éxito',
      },
      edit: {
        indicator: 'Modificar la encuesta',
        title: 'Modificar la encuesta',
        created: 'Encuesta modificada con éxito',
      },
    },
    units: {
      button: 'Añadir unidad',
    },
    parameter: {
      button: 'Añadir un parámetro',
      key: 'Clave',
      value: 'Valor',
      text: 'Texto',
      file: 'Archivo',
      json: 'Json',
    },
    typeCenter: {
      button: 'Añadir un tipo de centro médico',
    },
    typeStaff: {
      button: 'Añadir tipo de personal',
      personalType: '¿El tipo de Personal sera asignado a un Medico Tratante?',
      code: 'Code',
      codeSystem: 'Code System',
    },
    specialities: {
      button: 'Añadir especialidad',
    },
    relationships: {
      button: 'Añadir relación',
    },
    medicalstaff: {
      button: 'Añadir personal médico',
    },
    providers: {
      button: 'Añadir proveedor',
    },
    healthcondition: {
      button: 'Añadir  estado de salud',
    },
  },
  sidebar: {
    smartTest: 'SmartTest',
    dashboard: 'Panel de control',
    patients: 'Pacientes',
    groups: 'Grupos',
    mstaff: 'Personal médico',
    mcenter: 'Centros IDP',
    users: 'Usuarios',
    configuration: 'Configuración',
    indicator: 'Indicadores',
    questionaries: 'Cuestionarios',
    frequencie: 'Frecuencias',
    provider: 'Proveedores',
    profile: 'Perfiles',
    relation: 'Relaciones',
    specialty: 'Especialidades',
    typestaff: 'Tipo de personal',
    typecenter: 'Tipo de centro médico',
    unities: 'Unidades',
    healthcondition: 'Estado de salud',
    homePredict: 'HomePredict',
    parameter: 'Parámetros',
  },
  breadcrumb: {
    smartTest: 'SmartTest',
    dashboard: 'Panel de control',
    units: 'Unidades',
    patient: 'Paciente',
    groupsPatient: 'Grupos de pacientes',
    groupNewAssociate: 'Nuevo grupo - Miembro',
    mcenter: 'Centro médico',
    centerIDP: 'Centro IDP',
    typeStaff: 'Tipo de personal',
    medicalStaff: 'Personal médico',
    newPatient: 'Nuevo paciente',
    newStaff: 'Nuevo personal médico',
    detailStaff: 'Detalle del personal médico',
    typeMedicalCenter: 'Tipo de centro médico',
    newcenterIDP: 'Nuevo Centro de IDP',
    specialties: 'Especialidad',
    relationships: 'Relaciones',
    providers: 'Proveedores',
    healthConditions: 'Estado de salud',
    healthCondition: 'Estado de salud',
    frequencies: 'Frecuencias',
    questionaries: 'Cuestionarios',
    newQuestionaries: 'Nuevo cuestionario',
    detailQuestionarie: 'Detalle del cuestionario',
    indicators: 'Indicador',
    newIndicators: 'Nuevo indicador',
    detailIndicators: 'Detalle del indicador',
    profiles: 'Perfil',
    newProfiles: 'Nuevo perfil',
    detailProfiles: 'Detalle del perfil',
    editIndicatorsProfiles: 'Añadir/borrar indicadores',
    associateQuestionariesProfiles: 'Asociar cuestionario',
    users: 'Usuario',
    newUser: 'Nuevo usuario',
    detailUser: 'Detalle del usuario',
    add: 'Añadir',
    edit: 'Editar',
    detail: 'Detalle',
    homePredict: 'HomePredict',
    parameter: 'parámetro',
  },
  form: {
    fieldEmail: 'Enviar un correo a',
    fieldPassword: 'Contraseña',
    fieldConfirmPassword: 'Confirmar contraseña',
    fieldValidationPasswords: 'Las contraseñas no son iguales',
    fieldValidationSecuritypasswords:
      'La contraseña no cumple las políticas de seguridad',
    button: {
      actionSave: 'Guardar',
    },
    searchBar: 'Introduzca el texto de búsqueda',
  },
  auth: {
    name: 'IDP Santé',
    titleLogin: 'Identifícate',
    authTwoFactor: 'Autenticación de dos factores',
    authTwilio: 'Autenticacion por SMS',
    placeholderCode: 'Introduzca el código',
    placeholderUsername: 'Nombre de usuario o correo electrónico',
    placeholderPassword: 'Contraseña',
    placeholderForgetPassword: 'Dirección de correo electrónico',
    forgetPassSubTitle: '¿Ha olvidado su contraseña?',
    forgetPassDescription:
      'Introduce tu dirección de correo electrónico y te enviaremos un enlace para recuperarla.',
    invalidData: 'Credenciales no válidas',
    invalidcenter:
      'Actualmente no puede conectarse, por favor, póngase en contacto con su administrador.',
    invalidCode: 'Código Google inválido',
    invalidSmsCode: 'Código SMS no válido',
    codeWasSend: 'Se ha enviado un nuevo código por SMS',
    codeWasNotSend:
      'Se ha producido un error al enviar el código, inténtelo de nuevo más tarde.',
    sendRequest: 'Enviar solicitud',
    reutnToLogin: "Volver al inicio de sesión",
    cancelRequest: 'Cancelar la solicitud',
    resetPassSubTitle: 'Recuperar la contraseña',
    loginMethod: 'Método de acceso',
    loginMethodSelected:
      'Seleccione el metodo a utilizar para la autenticacion de la cuenta',
    phoneNumber: 'Número de teléfono',
    resetPassDescription:
      'Por favor, introduzca una nueva contraseña y confirme.',
    enterValidatePhoneNumber:
      'Introduzca un número de teléfono válido para realizar el  envío del código de autenticación.',
    resetPassSave: 'Guardar',
    signInButton: 'Ingresar',
    tryAgain: 'Intenta de nuevo',
    resendCode: 'Reenviar código',
    changeNumber: 'Cambiar el número de teléfono',
    signInForgotPass: '¿Ha olvidado su contraseña?',
    signUpTermsConditions: 'Acepto los términos y condiciones',
    signUpButton: 'Registro',
    signUpAlreadyAccount: '¿Ya tiene una cuenta? Entra.',
    textNeedChangePassword:
      'Su contraseña ha caducado. Por razones de seguridad, debe cambiar su contraseña.',
    textLinkChangePassword: 'Cambiar contraseña',
    textGenerateNewPassword:
      'Para continuar, se ha enviado un enlace a su dirección de correo electrónico para generar su contraseña.',
    textOne2Factor:
      'Escanee el código QR con la aplicación Google Authenticator en su teléfono.',
    textTwo2Factor:
      'El código de Google Authenticator para IDP SANTE aparece en la lista.',
    textTree2Factor:
      'Si no puedes escanear el código, puedes introducir manualmente la clave de configuración en la aplicación Google Authenticator:',
    textFour2Factor:
      'Para las siguientes conexiones, puede utilizar directamente los códigos generados por Google Authenticator.',
    textEmailSend: 'Enviar',
    textEmailReSend: 'Reenviar',
    textEmailCancel: 'Cancelar',
    textAttention: 'Atención',
    textSliderPasswordWeak: 'débil',
    textSliderPasswordStrong: 'fuerte',
    textSecurityPoliciesTitle: 'La contraseña debe contener',
    textSecurityPoliciesRule1: '8 o más caracteres',
    textSecurityPoliciesRule2: 'al menos una letra mayúscula y otra minúscula',
    textSecurityPoliciesRule3:
      'al menos un número y un carácter especial @$!%*?&',
    buttonTextBackLogin: 'Volver a la página de inicio de sesión',
    sendLinkSuccess: 'enlace enviado al correo electrónico',
    alert: 'Advertencia',
    oldPasswordAlert:
      'Contraseña  inválida. Debe ser diferente de los tres últimos utilizados anteriormente.',
    resetPassSuccess: 'Restablecimiento de la contraseña con éxito',
    SMS: 'SMS',
    authenticator: 'Autenticador de Google',
  },
  homePredict: {
    title: 'HomePredict',
    errorUpdatePatient:
      'El registro del paciente no puede ser actualizado en este momento',
    errorUpdatePatients:
      'Actualmente no es posible actualizar los registros de los pacientes',
  },
  medicalstaff: {
    add: 'Añadir equipo médico',
    edit: 'Editar personal médico',
    editShort: 'Editar',
    save: 'Guardar',
    back: 'Volver',
    personalInformation: 'Informacion del tipo de personal',
    treatingPhysician: 'Médico tratante',
    tableheader: {
      fullName: 'Nombre completo',
      type: 'Tipo',
      address: 'Dirección',
      specialty: 'Especialidad',
      email: 'Dirección de correo electrónico',
      centersIDP: 'Centros IDP',
      status: 'Estatus',
    },
    form: {
      return: 'Regresar al listado del personal medico',
      created: 'El personal médico ha sido creado con éxito',
      edited: 'El personal médico ha sido modificado con éxito',
      first_name: 'Nombre',
      last_name: 'Apellido',
      date_of_birth: 'Fecha de nacimiento',
      gender: 'Género',
      mail_address: 'Dirección de correo electrónico',
      phone_number: 'Número de teléfono',
      medical_center: 'Centro médico',
      staff_type: 'Tipo de personal',
      speciality: 'Especialidad',
      age: 'Edad',
      codeInsee: 'Lugar de nacimiento',
      code: 'Code',
      codeSystem: 'Code System',
      rpps: 'RPPS',
    },
    show: {
      personalInfo: 'Información personal',
      professionalInfo: 'Información profesional',
      male: 'Hombre',
      female: 'Mujer',
    },
  },
  patient: {
    title: 'Lista general de pacientes',
    teleExpertise: {
      selectAll: "Marcar todo",
      unselectAll: "Desmarcar todo",
      submit: "Enviar solicitud",
      request: {
        success: 'La solicitud se envió exitosamente',
        error: "Ocurrio un error al enviar la solicitud"
      },
      youHave: 'Tienes',
      selectedFiles: 'archivos seleccionados',
      reason: {
        title: 'Motivo de la solicitud',
      },
      selects: {
        title: 'Seleccione los archivos que desea adjuntar',
        smartPredict: 'Reporte Smartpredict',
        right_ear: "Imágenes de Oído derecho",
        left_ear: "Imágenes de Oído izquierdo",
        extended: 'Audio Corazón / Pulmón',
        lungs: 'Audio Pulmon',
        heart: 'Audio Corazón',
        vdsi: 'Reporte VDSI',
      },
    },
    tableheader: {
      type: 'Tipo',
      speciality: 'Especialidad',
      fullName: 'Nombre completo',
      name: 'Nombre',
      gender: 'Género',
      age: 'Edad',
      fragilityScore: 'Puntuación de fragilidad',
      fragility: 'Fecha de la próxima cita',
      stability: 'Índice de estabilidad',
      status: 'Estatus',
      vdsi: 'SLIMS',
    },
    form: {
      heart: 'Corazón',
      lungs: 'Pulmón',
      extended: 'Corazón/Pulmón',
      takePhoto: 'Toma una foto',
      name: 'Nombre (*)',
      validate_to: 'Válido para',
      observation: 'Observaciones',
      lastname: 'Apellido (*)',
      birth: 'Fecha de nacimiento (*)',
      sex: 'Género (*)',
      left_ear: 'Oído izquierdo',
      right_ear: 'Oído derecho',
      gender: {
        female: 'Femenino',
        male: 'Masculino',
      },
      hands: {
        right: 'Diestro',
        left: 'Zurdo',
      },
      height: 'Estatura cm (*)',
      weight: 'Peso Kg (*)',
      right_hand: 'Principal (*)',
      place_birth: 'País de nacimiento (*)',
      address: 'Dirección (*)',
      email: 'Correo electrónico (*)',
      phone: 'Nº de teléfono (*)',
      SSN: 'Nº de la Seguridad Social',
      zipCode: 'Código postal',
      center_id: 'Centro médico (*)',
      health: 'Salud',
      codeInsee: 'Lugar de nacimiento',
      date: 'Filtrar por fecha',
      searchFilter: 'Filtros de busqueda',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de finalización',
      clearDate: 'Limpiar los filtros',
      department: 'Departamentos',
      community: 'Comunidad',
      cc: 'DNI - Documento Nacional de Identidad',
      nif: 'NIF - Número de identificación fiscal',
      niss: 'NUSS- Número de la Seguridad Social',
      sns: 'SNS - Número de usuario de SNS',
      comments: 'Información adicional',
      dni: 'DNI - Documento Nacional de Identidad',
      nuss: 'NUSS - Número de la Seguridad Social',
    },
    hands: {
      right: 'Diestro',
      left: 'Zurdo',
    },
    show: {
      personalInfo: 'Información personal',
      contactInfo: 'Información de contacto',
      healthInfo: 'Condiciones de salud',
      medicalInfo: 'Personal médico',
      principalDoctor: 'Médico principal',
      documents: 'Documentos legales',
      observation: 'Observaciones',
      add: 'Añadir',
      update: 'Actualizar',
    },
    patientInfo: {
      email: 'Correo electrónico',
      phone: 'Nº teléfono',
      birth: 'Fecha de nacimiento',
      address: 'Dirección postal',
      country: 'País',
      hand: 'Mano dominante',
      height: 'Altura',
      weight: 'Peso',
      SSN: 'Nº Seguridad Social',
      male: 'Hombre',
      female: 'Mujer',
      medicalCenter: 'Centro médico asociado',
      sex: 'Género',
      department: 'Departamento',
      community: 'Comunidad',
      cc: 'DNI - Documento Nacional de Identidad',
      nif: 'Identificación fiscal',
      niss: 'NUSS- Número de la Seguridad Social',
      sns: 'Servicio Nacional de Salud',
      comments: 'Información adicional',
      dni: 'Documento Nacional de Identidad',
      nuss: 'Número de la Seguridad Social',
    },
    detail: {
      document: {
        firmdate: 'Fecha de la firma',
        description: 'Descripción',
        sendDocument: 'Introduzca su dirección de correo electrónico',
        success: 'Los documentos legales han sido enviados con éxito',
        error: 'Hubo un problema al enviar los documentos legales ',
        view: 'Ver Documento Guardado',
        send: 'Enviar el documento',
        accepted: 'Aceptar',
        declined: 'Rechazado',
      },
      pdf: {
        description:
          'Añade las direcciones de correo electrónico separadas por comas.',
        success: 'El informe de SmartPredict se ha enviado con éxito',
        errorSend:
          'Se ha producido un error al enviar el informe SmartPredict. ',
      },
      tugInformation: 'Información del TUG',
      BilanTug: 'Test TUG',
      subTabs: {
        tabGraphique: 'Gráfico',
        tabIndicators: 'Indicadores',
        tabBalance: 'Test',
      },
      tabObservations: {
        observation: 'Observaciones',
        allDates: 'Todas las fechas',
      },
      tabSmartPredict: {
        cardTab: {
          description: 'Descripción',
          interpretation: 'Interpretación',
          recomendation: 'Recomendación',
        },
        stadistic: {
          fragilite: {
            bad: 'Frágil',
            stable: 'Pré-frágil',
            good: 'Robusto',
          },
          equilibre: {
            bad: 'Inestable',
            good: 'Estable',
          },
          risqueChute: {
            bad: 'Alto',
            good: 'Bajo',
          },
        },
        fragility: 'Fragilidad',
        marcha: 'Caminata',
        psychological: 'Puntuaciones',
        physiologique: 'Evaluación fisiológica',
        name: 'SmartPredict',
        dataForDate: 'Visualización de datos de',
        impedance: "Bioimpedancia",
        pdf: {
          sentPdf: 'Enviar PDF',
          downloadPdf: 'Descargar PDF',
          documentOption: `Opciones de documento`,
          IheXdmReport: `Reporte IHE XDM`,
          smartPredictReport: `Reporte SmartPredict`,
          seePdf: `Ver PDF`,
        },
        firtsIndicators: {
          time_TUG: 'Tiempo TUG',
          walk_aller_plus_retour: 'Duración de la caminata de ida y vuelta',
          number_of_steps_aller_retour: 'Número de pasos',
          risque_TUG: 'Riesgo de caída',
        },
        graphic: {
          labelDates: 'Fechas',
          labelValue: 'Valor',
          labelLoadingText: 'Cargando ...',
        },
      },
      tabInformation: {
        name: 'Información general',
        openEyes: 'Ojos abiertos',
        closeEyes: 'Ojos cerrados',
        notInformation: 'No hay indicadores disponibles',
        mainDoctor: 'Médico',
        years: 'años',
        vdsi: 'Bilans VDSI',
        teleExpertise: 'Tele experticia'
      },
      tabSmartDetect: {
        tab: 'SmartDetect',
        openEyes: 'Ojos abiertos',
        closeEyes: 'Ojos cerrados',
        cardsIndicators: {
          pressionForce: 'Fuerza de prensión',
          maxLeft: 'Máxima izquierda',
          maxRight: 'Máxima derecho',
          WeightDistribution: 'Distribución del peso',
        },
      },
      tabTip: {
        tips: 'Consejos',
        enterTheSearchText: 'Introduzca el texto de búsqueda',
        noResults: 'Sin resultados',
        simple: 'Consejo único',
        pack: 'Paquete de consejos',
        specialty: 'Especialidad',
        configurationList: 'Lista de configuraciones',
        programmingWasSuccessfullyCreated:
          'Se creó la programación exitosamente ',

        modal: {
          username: 'Nombre de usuario',
          password: 'Contraseña',
          login: 'Inicio de sesión Certipair',
          incorrectPasswordUsernamePlease:
            'Contraseña y/o nombre de usuario incorrecto, por favor volver a ingresar',
        },
        form: {
          now: 'Ahora',
          certifyThatHaveObtained:
            'Certifico que he obtenido el consentimiento del paciente para enviar mensajes sanitarios por SMS a través de la aplicación CertiPair.',
        },
        buttons: {
          hideAllMessages: 'Ocultar todos los mensajes',
          viewMessages: 'Ver los mensajes',
        },
        tabOne: {
          adviceAssociatedWithThisPatient: 'Consejos asociados a este paciente',

          newCouncil: 'Nuevo Consejo',
          message: 'Mensaje',
          status: 'Estado',
          startDate: 'Fecha de creación',
          endDate: 'Fecha de envío',
          sent: 'Enviado',
          canceled: 'Cancelado',
        },
        tapTwo: {
          and: 'y',
          hours: 'horas',
          deliveryTime: 'Plazo de entrega',
          packageDetails: 'Detalles del paquete',
          messagesIn: 'mensajes en',
          days: 'días',
          months: 'Meses',
          webLink: 'Enlace web',
          selectTheMessages: 'Seleccionar los mensajes',
          all: 'Todos',
          myFavorites: 'Mis favoritos',
          professions: 'Profesiones',
          noAdviceFound: 'No se encontraron consejos',
          tip: 'Consejo',
          attachment: 'Adjunto',
          whenDoYouWantToSendThisMessage: '¿Cuándo quiere enviar este mensaje?',
          selectDate: 'Seleccione una fecha',
          selectTime: 'Seleccione una hora',
          schedule: 'Horario',
          backToListOfMessages: 'Volver a la lista de mensajes',
        },
      },
    },
    back: 'Volver',
    next: 'Siguiente',
    finish: 'Finalizar',
  },
  validators: {
    pdfIsNotValidate: 'La versión del PDF no es compatible',
    zipCode: 'El código postal no pertenece a ninguna comunidad',
    zipCodeNotAssociate:
      'No se  encontró ningún código postal asociado al departamento',
    invalidZipCode: 'El código postal no es válido',
    emplyEmail: 'Se requiere un correo electrónico',
    emplyPassword: 'Se requiere una contraseña',
    emplyCodeGoogle: 'se requiere el código de google',
    invalidMacAddress: 'Dirección MAC no válida',
    invalidEmail: 'Formato de correo electrónico no válido',
    emailRegistered: 'El correo electrónico está registrado',
    required: 'Campo requerido',
    jsonIsNotValidate: "El formato JSON no es válido",
    number: 'El campo debe ser numérico',
    emailExist: 'El correo electrónico ya existe',
    phoneExist: 'El nº de teléfono ya existe',
    addressExist: 'La dirección ya existe',
    invalidPhone: 'Introduzca un número de teléfono válido',
    invalidHeight: 'La altura debe estar entre 1 y 999 Cm',
    invalidWeight: 'El peso debe estar entre 1 y 999 Kg.',
    license: 'La licencia no es válida',
    name: 'El nombre no es válido',
    lastname: 'El apellido no es válido',
    image: 'Formato de imagen no válido',
    minLength: 'El campo debe contener',
    num: 'números',
    characters: 'Caracteres',
    imageSize: 'El peso máximo de la imagen debe ser de 1MB',
    undefinedLatitudeAndLongitude: 'Latitud y longitud indefinidas',
  },
  alt: {
    logoIdp: 'Logotipo IDP',
    welcomeIdp: 'Bienvenido IDP',
  },
  languages: {
    french: 'Francés',
    portuguese: 'Portugués',
    spanish: 'Español',
    english: 'Ingles',
    spanishCo: "Colombiano"
  },
}

export default es

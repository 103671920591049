const en = {
  general: {
    download: "Download",
    images: 'Images',
    dataDowload: 'Download Of Data',
    dataGeneralDowload: 'Questionnaire data',
    evolutionChart: 'Evolution Curve',
    downloadCsv: 'Download CSV file',
    generateFile: 'Generate file',
    noOptions: 'No hay opciones',
    dateLocale: 'en-GB',
    dateFormat: 'dd-MM-yyyy',
    timeFormat: 'HH:mm',
    dateTimeFormat: 'dd-MM-yyyy hh:mm yy',
    dateFormatGeneral: 'yyyy-MM-dd HH:mm:ss',
    dataNotFound: 'Unavailable',
    dateOfResponse: 'Date of response',
    search: 'Research',
    selectDate: 'Select',
    secondsUnit: 's',
    angleUnit: '°',
    stepUnit: 'not',
    mostRecent: 'The most recents',
    name: 'name',
    notFound: 'Information not found',
    actions: 'Shares',
    copyright: 'IDPSanté - © Copyright all rights reserved',
    notFound404: 'The page you are looking for was not found...',
    notFound401: 'Please log in again for security reasons',
    error404: 'Page not found',
    error401: 'Your session has expired',
    moreInfo: 'See more information',
    welcome: 'Welcome',
    question: 'Question',
    lastUpdate: 'Last update',
    sendEmail: 'Send an email',
    total: 'Total',
    right: 'Straight',
    left: 'Left',
    fragilityFried: 'Fragility fried',
    fragilityIndicators: 'Fragility indicators',
    bilanMarche: 'Walk',
    physiologique: 'Physiological',
    psychologique: 'Psychology',
    placeholderMedical: 'Search by name',
    preview: 'Preview',
    score: 'Score',
    response: 'Response',
    patientMenu: {
      name: 'Fullname',
      lastName: 'Lastname',
      birthday: 'Date of Birth',
      sex: 'Gender',
      address: 'Address',
      phone: 'Phone number',
      score: 'Fragility score',
      dateEnd: 'Date of last smartpredict',
      medical_staff: 'Medical staff',
      value: 'Answer to the question',
    },
    fragilityScore: {
      warning: 'Pre-frail',
      danger: 'Frail',
      good: 'Non frail',
      noScore: 'No score',
    },
    gender: {
      male: 'Man',
      female: 'Woman',
      gender: 'Gender',
    },
  },
  components: {
    modal: {
      value: 'Value(*)',
      valueOptional: 'Value',
      key: 'Key(*)',
      children: 'Children',
    },
    refresh: 'Refresh',
    sendMyself: 'Send the report to my email address',
    status: 'Active',
    warningAtention: 'Warning!',
    warningDelete:
      'This action will delete the configuration, are you sure you want to continue?',
    deleteMessage: 'Are you sure you want to delete this item?',
    uploadImage: 'Download picture',
    changeStatus: 'The status has been changed successfully',
    errorStatus: 'The status could not be changed',
    required: 'Mandatory',
    noRequired: 'Not required',
    ok: 'OK',
    pickerPlaceholder: 'To select',
    cancel: 'Cancel',
    send: 'To send',
    increment: 'Add',
    delete: 'To delete',
    deleteError: 'An error occurred in the application',
    alert: 'Record created successfully',
    alertDelete: 'Record deleted successfully',
    alertEdit: 'Record changed successfully',
    update: 'Update',
    createItem: 'Add record',
    editItem: 'Registration update',
    deleteItem: 'The deletion is final, would you like to confirm?',
    main: 'Main page',
    backLogin: 'Go to login',
    homeCard: 'Patients',
    back: 'Return',
    next: 'Next',
    success: 'Request processed',
    yes: 'Yes',
    profileIcon: {
      myProfile: 'My profile',
      logout: 'Sign out',
    },
    traductions: {
      missingTraductionTitle: 'Fields to translate!',
      missingTraductiontext:
        'Not all fields are translated. Are you sure you want to send this information?',
    },
  },
  myProfile: {
    title: 'My profile',
    myInfo: 'my information',
    dataUpdated: 'Updated data',
    emailDirection: 'Mail address:',
    editInformation: 'Edit user',
    resetPasswordTitle: 'Restore Password',
    editProfileTitle: 'Modify my personal information',
    resetPassword: 'Confirm the new password',
    inputs: {
      name: 'First name',
      nameCenter: 'Medical center name',
      lastname: 'name',
      phone: 'Phone number',
      birth: 'Date of Birth',
      birthCenter: 'Date of creation',
    },
  },
  cruds: {
    healthCondition: {
      noData: 'Information not found',
      addTranslate: 'Add',
      editTranslate: 'To modify',
      listItem: 'List of subcategories',
      missingItem: 'Translations in progress',
      create: 'New health status',
      created: 'The health status was created',
      edited: 'Health status modified',
      edit: 'Change health status',
      detail: 'Health status details',
      generalInformation: 'General Information',
      show: 'Health status details',
      translate: 'Translation',
      return: 'Back to health codes list',
      csv: 'Find a health code',
      category: 'Main category name *',
      subCategory: 'Subcategories *',
      instructions:
        'You can add as many subcategories as you want. Separate each subcategory with a comma or simply drag and drop a CSV file here.',
      translateInstruction:
        'You can type each translation on the corresponding line, or you can drop a CSV file.',
    },
    smartTest: {
      statusSelector: 'Status selector',
      indicator: 'Does this step generate an indicator?',
      dataSourceId: 'Datasource ID',
      enum: {
        create: 'Create',
        update: 'Update',
        recordless: 'Without registration',
      },
      goToQuestions: 'Go to the questions section',
      questionarie: 'Questionnaire',
      smartQuestionnaire: 'Smart Questionnaire',
      kinvent_grip: 'K-Physio Grip',
      kinvent_gait: 'K-Physio Gait',
      kinvent_stance: 'K-Physio Stance',
      kinvent_singlemove: 'K-Physio SingleMove',
      kinvent_open: 'K-Physio Open',
      aminogram_test: "Impedance test",
      kinvent_gait_no_cal: "Kinvent gait no cal",
      kinvent_gait_three_no_cal: "Kinvent gait three no cal",
      xsens: 'X-sense',
      smartTest: 'Smart Test',
      kforceStrength: 'Kforce Strength',
      kforceBalance: 'Kforce Balance',
      teleconsultation: 'Teleconsultation',
      button: 'Add a new test',
      block: 'Block',
      tableheader: {
        title: 'Title',
        description: 'Description',
        typeTest: 'Type of testing',
        numberSensors: 'No of Sensors',
        typeProvider: 'Type of supplier',
        status: 'status',
        actions: 'Action',
        position: 'Position',
      },
      associateStaffType: {
        help: 'Select the specializations that will be able to view the SmartTests, otherwise all specializations will be able to view the SmartTests.',
        associate: 'Staff Association',
        returnList: 'Back to the smart tests list',
        success: 'Successful partnership',
        ok: 'Staff association was carried out',
        smartTest: 'SmartTest',
      },
      form: {
        actionSelect: 'Action selector',
        name: 'SmartTest title',
        updateProvider: 'Update provider?',
        idpApp: 'IDP-APP',
        idpSmart: 'IDP-SMART',
        providers: 'Supplier',
        sensorsLabel: 'No of sensors that will be used',
        sensors: 'No of sensors',
        description: 'Description of SmartTest',
        blockType: 'Block type',
        questionarieSelect: 'Choose a questionnaire',
        smartTestSelect: 'Choose a smart test',
        instructions: 'Block statements',
        instructionTest: 'Instructions for the smart test',
        instructionSensors: 'Sensor Instructions',
        thirdOption: 'Response time',
        icon: 'Test icon',
        position: 'Order Position',
      },
      steps: {
        stepOne: { step: 'Step 1', label: 'General Information' },
        stepTwo: { step: 'Step 2', label: 'Process Setup' },
        stepThree: { step: 'Step 3', label: 'Language definition' },
        questions: {
          stepOne: { step: 'Step 1', label: 'Editing Questions' },
          stepTwo: { step: 'Step 2', label: 'Language definition' },
        },
      },
      create: {
        indicator: 'New SmartTest',
        title: 'Create a new SmartTest',
        subtitle: 'New SmartTest',
        newBlock: 'Add block',
        created: 'The smart test has been created successfully',
        return: 'Back to smart test list',
      },
      edit: {
        viewSavedImage: 'View Saved Image',
        viewImageInstructions: 'See image for instructions',
        viewImageSensors: 'See the image of the sensors',
        instructional_picture: 'See image for instructions',
        data_sensors: 'See the image of the sensors',
        indicator: 'Modify a SmartTest',
        title: 'Modify the current smart test',
        subtitle: 'Modify a SmartTest',
        created: 'The smart test has been modified successfully',
        return: 'Back to smart test list',
      },
      questions: {
        numberOfQuestions: 'No of questions',
        indicator: 'Steps',
        title: 'List of steps',
        edit: {
          title: 'Edit the questionnaire',
          return: 'Back to the list of steps',
        },
        form: {
          nameQuestionLabel: 'Question title',
          typeofQuestionLabel: 'Question type',
          option: 'Option',
        },
      },
      selectionSimple: 'Simple selection',
      selectionMultiple: 'Multiple selection',
      open: 'Free question',
    },
    patients: {
      anonimusFieldsDownload: 'Descargar archivos anonimizados',
      return: 'Back to patient list',
      button: 'Add Patient',
      steps: {
        stepOne: 'Step 1',
        stepTwo: '2nd step',
      },
      labels: {
        stepOne: 'Patient information',
        stepTwo: 'Referring Practitioner and Medical Staff',
      },
      create: {
        indicator: 'New patient',
        title: 'Create a new patient',
        created: 'The patient was created successfully',
      },
      edit: {
        indicator: 'Edit Patient',
        title: 'Edit a patient',
        created: 'The patient was successfully modified',
      },
    },
    medicalCenters: {
      return: 'Back to the list of centers',
      htmlName: 'Name of the html file',
      identifiers: 'Identifiers',
      tableheader: {
        fullName: 'Name',
        type: 'Gender',
        email: 'Mail address',
        status: 'Status',
        address: 'Address',
      },
      informationList: {
        info1: {
          title:
            'The HTML should correspond only to the body of the document, the header and footer will be added automatically.',
        },
        info2: {
          title:
            'In the HTML, you can include the following tags, which will be replaced by the information stored on our platform:',
          childs: {
            c1: "patient's full name",
            c2: "adthe patient's address",
            c3: 'patient identification number',
            c4: "expirethe expiration date of the patient's identification document",
            c5: 'the city of the medical center',
            c6: 'the date the document is signed',
            c7: "signthe patient's signature",
          },
        },
        info3: {
          title: 'The HTML file must have a name',
        },
      },
      createButton: 'New IDP center',
      show: {
        indicator: 'See details',
        titlePage: 'View IDP center details',
        informationTitle: 'Center information',
        personalTitle: 'Associated medical staff',
        contactTitle: 'Contact persons',
        noContacts: 'No contacts linked',
        back: 'To return to',
        address: 'Center address',
      },
      create: {
        titlePage: 'Create new center',
        addressSubtitle: 'Add center address',
        addressTitle: 'Center address',
        indicator: 'New center',
        created: 'The center was successfully established',
        licenseTitle: 'Add license number',
        licenseSubtitle: 'License number',
        informationCenter: 'Add center information',
        consentDocument: 'Consent document',
        confidentialDocument: 'Confidential document',
      },
      edit: {
        titlePage: 'Editing a center',
        addressSubtitle: 'Medical Center Update',
        addressTitle: 'Center address',
        indicator: 'Update center',
        created: 'The center has been changed successfully',
        informationCenter: 'Updating center information',
      },
      form: {
        name: 'Center name',
        email: 'Mail address',
        phone: 'Phone number',
        country: 'Country',
        city: 'City',
        address: 'Address',
        typeCenter: 'Type of medical center',
        license: 'License number',
        updateLicense: 'License number update',
        nameOfTheConsentDocument: 'Name of the consent document',
        descriptionOfTheConsentDocument: 'Description of consent document',
      },
      contacts: {
        button: 'Add a contact',
        title: 'Add contact information',
        subtitle: 'List of associated contacts',
        form: {
          name: 'First name',
          lastname: 'name',
          email: 'E-mail',
          phone: 'Phone',
          fullName: 'Last name and first name',
          speciality: 'Speciality',
        },
      },
      sensors: {
        title: 'Configuring a SmartPredict device',
        detail: 'SmartPredict device',
        kit: 'Set',
        subtitle: 'MAC of the sensors',
        button: 'Add sensor set',
        form: {
          sensor: 'Sensor',
        },
      },
      biody: {
        title: "Configuration of a Biody device",
        biody: "Biody",
        titleShow: "Biody device",
        button: "Add a device",
        form: {
          id: "Device",
          license: "License"
        }
      }
    },
    users: {
      return: 'Back to user list',
      tableheader: {
        fullName: 'Last name and first name',
        rol: 'Role',
        age: 'Age',
        email: 'Mail address',
        verified: 'Checked',
      },
      crateButton: 'Create a new user',
      create: {
        titlePage: 'Create a user',
        indicator: 'New user',
        created: 'User was successfully created',
        userInfoTitle: 'User Information',
        userPasswordTitle: 'Creation of a password',
      },
      edit: {
        titlePage: 'Update a user',
        indicator: 'Edit user',
        created: 'User changed successfully',
      },
      inputs: {
        name: 'First name(*)',
        lastname: 'Name(*)',
        phone: 'Phone number(*)',
        birth: 'Date of Birth(*)',
        email: 'Mail address(*)',
        locale: 'Language(*)',
      },
    },
    profiles: {
      return: 'Back to list of balance sheets',
      list: {
        button: 'New assessment',
      },
      create: {
        indicator: 'New assessment',
        title: 'New assessment',
        step: {
          first: 'Step 1',
          second: 'Step 2',
          third: 'Step 3',
        },
        stepLabel: {
          first: 'General Information',
          second: 'List of indicators',
          third: 'Definition of languages',
        },
        nameLabel: 'Assessment name(*)',
        descriptionLabel: 'Description of the Assessment(*)',
        providerLabel: 'Supplier(*)',
        indicatorsSelection:
          'Sélectionnez les indicateurs que vous souhaitez ajouter à ce bilan',
        indicators: 'Indicators',
        indicatorsTitle: 'Link Indicators',
        translateTitle: 'Please translate the following texts',
        created: 'The balance sheet was created successfully',
        translateLabel: 'original text',
        noImage: 'No instructional image',
        textToTranslate: 'Text to be translated',
        name: 'Name',
        description: 'Description',
      },
      edit: {
        indicator: 'Assessment update',
        title: 'Modify Assessment',
        edited: 'TheAssessment has been updated successfully',
      },
    },
    indicators: {
      return: 'Back to the list of indicators',
      list: {
        button: 'New indicator',
      },
      create: {
        indicator: 'New indicator',
        title: 'Create a new indicator',
        step: {
          first: 'Step 1',
          second: 'Step 2',
          third: 'Step 3',
        },
        stepLabel: {
          first: 'General Information',
          second: 'Specific annotations',
          third: 'Definition of languages',
        },
        nameLabel: 'Indicator name(*)',
        dataSourceLabel: 'Data source(*)',
        descriptionLabel: 'Description of the indicator(*)',
        frequencieLabel: 'Frequency(*)',
        unitLabel: 'Unit type(*)',
        acronymLabel: 'Acronym(*)',
        recomendationLabel: 'Recommendation',
        interpretationLabel: 'Interpretation(*)',
        interpretation: 'Interpretation',
        valueLabel: 'Value',
        type: 'Data type',
        indicatorsSelection:
          'Sélectionnez les indicateurs que vous souhaitez ajouter à ce bilan',
        indicators: 'Indicators',
        indicatorsTitle: 'Link Indicators',
        translateTitle: 'Please translate the following texts',
        created: 'The indicator was created successfully',
        translateLabel: 'original text',
        textToTranslate: 'Text to be translated',
        name: 'Indicator name',
        description: 'Description of the indicator',
      },
      edit: {
        indicator: 'Updating an indicator',
        title: 'Updating an indicator',
        edited: 'The indicator was updated successfully',
      },
      type: {
        text: 'Text',
        number: 'Digital',
        image: 'Picture',
        static: 'Static',
        boolean: 'Boolean',
      },
      annotation: {
        button: 'Add a recommendation',
      },
    },
    questionaries: {
      return: 'Back to the list of questionnaires',
      statusButtom: 'Change',
      changeStatus: 'Do you want to change the status?',
      selectionSimple: 'Simple selection',
      selectionMultiple: 'Multiple selection',
      numeric: 'Digital',
      opened: 'Open',
      label: 'Label',
      option: 'Option',
      score: 'Score',
      list: {
        button: 'New quiz',
        status: 'Status',
        questionarie: 'Quiz',
      },
      create: {
        indicator: 'New Quiz',
        title: 'New Quiz',
        addOption: 'Add question',
        step: {
          first: 'Step 1',
          second: '2nd step',
          third: 'Step 3',
        },
        stepLabel: {
          first: 'General Information',
          second: 'Issues',
          third: 'Definition of languages',
        },
        nameLabel: 'Questionnaire name(*)',
        descriptionLabel: 'Instructions for the questionnaire(*)',
        nameQuestionLabel: 'Question title',
        typeofQuestionLabel: 'Question type',
        name: 'Questionnaire name',
        description: 'Description',
        instructions: 'Instructions for the questionnaire',
        created: 'Questionnaire created successfully',
      },
      edit: {
        indicator: 'Edit quiz',
        title: 'Edit quiz',
        created: 'Questionnaire modified successfully',
      },
    },
    units: {
      button: 'Add unit',
    },
    parameter: {
      button: 'Add a parameter',
      key: 'key',
      value: 'Value',
      text: 'Text',
      file: 'File',
      json: 'Json',
    },
    typeCenter: {
      button: 'Add medical center type',
    },
    typeStaff: {
      button: 'Add a staff type',
      personalType:
        'Will the type of staff be assigned to a physician assistant?',
      code: 'Code',
      codeSystem: 'Code System',
    },
    specialities: {
      button: 'Add a specialty',
    },
    relationships: {
      button: 'Add a relationship',
    },
    medicalstaff: {
      button: 'Add a supplier',
    },
    providers: {
      button: 'Add health status',
    },
    healthcondition: {
      button: 'Add medical staff',
    },
  },
  sidebar: {
    smartTest: 'SmartTest',
    dashboard: 'Dashboard',
    patients: 'patients',
    groups: 'Groups',
    mstaff: 'Medical staff',
    mcenter: 'IDP Centers',
    users: 'Users',
    configuration: 'Configuration',
    indicator: 'Indicators',
    questionaries: 'Questionnaires',
    frequencie: 'Frequencies',
    provider: 'Suppliers',
    profile: 'Balance sheets',
    relation: 'Relationships',
    specialty: 'Specialties',
    typestaff: 'Staff Type',
    typecenter: 'Type of Medical Center',
    unities: 'Units',
    healthcondition: 'Health conditions',
    homePredict: 'HomePredict',
    parameter: 'Parameter',
    bilansMedicalStaff: 'Medical staff reports',
  },
  breadcrumb: {
    smartTest: 'SmartTest',
    dashboard: 'Dashboard',
    units: 'Units',
    patient: 'patients',
    groupsPatient: 'Patient groups',
    groupNewAssociate: 'New group - Associate',
    mcenter: 'Medical Center',
    centerIDP: 'IDP Center',
    typeStaff: 'Personal Type',
    medicalStaff: 'Medical staff',
    patients: 'patients',
    newPatient: 'new patient',
    newStaff: 'New medical staff',
    detailStaff: 'Detail of medical staff',
    typeMedicalCenter: 'Type of medical center',
    newcenterIDP: 'New IDP Center',
    specialties: 'Speciality',
    relationships: 'Relationships',
    providers: 'Suppliers',
    healthConditions: 'Known health history (optional)',
    healthCondition: 'Health',
    frequencies: 'Frequencies',
    questionaries: 'Questionnaires',
    newQuestionaries: 'New quiz',
    detailQuestionarie: 'Detail of the questionnaire',
    indicators: 'Indicators',
    newIndicators: 'New indicator',
    detailIndicators: 'Indicator details',
    profiles: 'Balance sheet',
    newProfiles: 'New balance sheet',
    detailProfiles: 'Balance sheet detail',
    editIndicatorsProfiles: 'Add/remove flags',
    associateQuestionariesProfiles: 'Association Questionnaire',
    users: 'Users',
    newUser: 'New user',
    detailUser: 'User detail',
    add: 'Add',
    edit: 'Edit',
    detail: 'Details',
    homePredict: 'HomePredict',
    parameter: 'Setting',
  },
  form: {
    fieldEmail: 'E-mail',
    fieldPassword: 'Password',
    fieldConfirmPassword: 'Confirm password',
    fieldValidationPasswords: 'Passwords are not the same',
    fieldValidationSecuritypasswords:
      'The password does not comply with the security policy',
    button: {
      actionSave: 'To safeguard',
    },
    searchBar: 'Enter search text',
  },
  auth: {
    name: 'IDP Health',
    titleLogin: 'Login',
    authTwoFactor: 'Two-factor authentication',
    authTwilio: 'Authentication by SMS',
    placeholderCode: 'Enter the code',
    placeholderUsername: 'username or email',
    placeholderPassword: 'Password',
    placeholderForgetPassword: 'Mail address',
    forgetPassSubTitle: 'Forgot your password ?',
    forgetPassDescription:
      'Fill in your email and we will send you a reset link.',
    forgetPassSuccessText:
      'We have sent you an email containing a link to reset your password.',
    invalidData: 'credentials are invalid',
    invalidcenter:
      'You cannot currently log in, please contact your administrator.',
    invalidCode: 'Invalid Google Authenticator code',
    invalidSmsCode: 'Invalid SMS code',
    codeWasSend: 'A new code was sent by SMS',
    codeWasNotSend:
      'An error occurred while sending the code, please try again later.',
    sendRequest: 'Save',
    reutnToLogin: "Back to login",
    cancelRequest: 'Cancel',
    resetPassSubTitle: 'Reset your password',
    loginMethod: 'Login method',
    loginMethodSelected: 'Select the method to use for account authentication',
    phoneNumber: 'Phone number',
    resetPassDescription: 'Enter a new password and confirm.',
    enterValidatePhoneNumber:
      'Enter a valid phone number to send you the authentication code.',
    resetPassSave: 'Save',
    signInButton: 'Login',
    tryAgain: 'Try again',
    resendCode: 'Resend Code',
    changeNumber: 'Change phone number',
    signInForgotPass: 'Forgot your password ?',
    signUpTermsConditions: 'I accept the terms and conditions',
    signUpButton: 'Register',
    signUpAlreadyAccount: 'Do you already have an account ? Log in.',
    textNeedChangePassword:
      'Your password has expired. For security reasons, it is necessary to change the password.',
    textLinkChangePassword: 'Change password',
    textGenerateNewPassword:
      'A link has been sent to your email address to generate the password.',
    textOne2Factor:
      'Scan the QRcode with the Google Authenticator application on your phone.',
    textTwo2Factor:
      'The Google Authenticator code for IDP SANTE appears in the list.',
    textTree2Factor:
      'If you can’t scan the code, you can manually enter the configuration key in the Google Authenticator application:',
    textFour2Factor:
      'For the following connections, you can directly use the codes generated by Google Authenticator.',
    textEmailSend: 'Send',
    textEmailReSend: 'Resend',
    textEmailCancel: 'Cancel',
    textAttention: 'Attention',
    textSliderPasswordWeak: 'Weak',
    textSliderPasswordStrong: 'Strong',
    textSecurityPoliciesTitle: 'The password must contain',
    textSecurityPoliciesRule1: '- 8 characters or more',
    textSecurityPoliciesRule2: '- At least one upper and one lower case letter',
    textSecurityPoliciesRule3:
      '- At least one digit and one special character are allowed',
    buttonTextBackLogin: 'Back to login page',
    sendLinkSuccess: 'Link sent to your email',
    alert: 'Warning',
    oldPasswordAlert:
      'Invalid password. The password must be different from the last three used.',
    resetPassSuccess: 'Successful password update',
    SMS: 'SMS',
    authenticator: 'Google authenticator',
  },
  homePredict: {
    title: 'HomePredict',
    errorUpdatePatient: 'Patient cannot be updated now',
    errorUpdatePatients:
      'Sorry, it is currently not possible to update patients',
  },
  medicalstaff: {
    add: 'Add medical staff',
    edit: 'Medical Staff Editor',
    editShort: 'Editor',
    save: 'Save',
    back: 'Back',
    personalInformation: 'Information on the type of personnel',
    treatingPhysician: 'Treating physician',
    tableheader: {
      fullName: 'Last name and first name',
      type: 'Kind',
      address: 'Address',
      specialty: 'Speciality',
      email: 'E-mail address',
      centersIDP: 'IDP Centers',
      status: 'Status',
    },
    form: {
      return: 'Back to medical staff list',
      created: 'The medical staff has been successfully created',
      edited: 'The medical staff has been successfully modified',
      first_name: 'First name',
      last_name: 'Surname',
      date_of_birth: 'Date of Birth',
      gender: 'Gender',
      mail_address: 'Mail address',
      phone_number: 'Phone number',
      medical_center: 'Medical Center',
      staff_type: 'Type of staff',
      speciality: 'Speciality',
      age: 'Age',
      codeInsee: 'Place of birth',
      code: 'Code',
      codeSystem: 'Code System',
      rpps: 'RPPS',
    },
    show: {
      personalInfo: 'Contact details',
      professionalInfo: 'Professional information',
      male: 'Man',
      female: 'Woman',
    },
  },
  patient: {
    title: 'General list of patients',
    teleExpertise: {
      selectAll: "Select all",
      unselectAll: "Unselect all",
      submit: "Send request",
      request: {
        success: 'The request was successfully sent',
        error: "An error occurred while sending the request"
      },
      youHave: 'You have',
      selectedFiles: 'selected files',
      reason: {
        title: 'Reason for request',
      },
      selects: {
        title: 'Select the files you wish to attach',
        smartPredict: 'Smartpredict report',
        right_ear: "Right ear pictures",
        left_ear: "Left ear images",
        extended: 'Heart / Lungs audio',
        lungs: 'Lungs Audio',
        heart: 'Heart Audio',
        vdsi: 'VDSI Report',
      },
    },
    tableheader: {
      type: 'Type',
      speciality: 'Speciality',
      fullName: 'Last name and first name',
      name: 'name',
      gender: 'Gender',
      age: 'Age',
      fragility: 'Next appointment',
      fragilityScore: 'Fragility score',
      stability: 'Stability Index',
      status: 'Active',
      vdsi: 'SLIMS',
    },
    form: {
      heart: 'Heart',
      lungs: 'Lung',
      extended: 'Heart/Lung',
      takePhoto: 'Take a photo',
      name: 'First name (*)',
      validate_to: 'Valid until',
      observation: 'Comments',
      lastname: 'Surname (*)',
      birth: 'Date of Birth (*)',
      sex: 'Gender (*)',
      left_ear: 'Left ear',
      right_ear: 'Right ear',
      gender: {
        female: 'Female',
        male: 'Male',
      },
      hands: {
        right: 'Right',
        left: 'Left',
      },
      height: 'Size cm (*)',
      weight: 'Weight Kg (*)',
      right_hand: 'Hand (*)',
      place_birth: 'Native country(*)',
      address: 'Address(*)',
      email: 'Mail address (*)',
      phone: 'Mobile number (*)',
      SSN: 'Social Security number',
      zipCode: 'Zip code',
      center_id: 'Medical Center (*)',
      health: 'Health',
      codeInsee: 'INSEE code',
      date: 'Filter by date',
      searchFilter: 'Search filters',
      startDate: 'Start date',
      endDate: 'Final date',
      clearDate: 'Clean filters',
      department: 'Department',
      community: 'Communities',
      cc: 'CC - Citizen card number',
      nif: 'NIF - Tax identification number',
      niss: 'NISS - Social Security Number',
      sns: 'SNS - SNS User IDs',
      comments: 'Further information',
      dni: 'DNI - National Identity Card',
      nuss: 'SSN - Social Security Number',
    },
    hands: {
      right: 'Right hand',
      left: 'Left hand',
    },
    show: {
      personalInfo: 'Contact details',
      contactInfo: 'Contact information',
      healthInfo: 'Health conditions',
      medicalInfo: 'Medical staff',
      principalDoctor: 'Referring practitioner',
      documents: 'Legal documents',
      observation: 'Comments',
      add: 'add',
      update: 'update',
    },
    patientInfo: {
      email: 'Mail address',
      phone: 'Mobile number',
      birth: 'Date of Birth',
      address: 'Address',
      country: 'Country',
      hand: 'Dominant hand',
      weight: 'Weight',
      height: 'Cut',
      SSN: 'Social Security number',
      male: 'Man',
      female: 'Woman',
      medicalCenter: 'Partner medical center',
      sex: 'Gender',
      department: 'Department',
      community: 'Community',
      cc: 'Citizen card',
      nif: 'Tax identification',
      niss: 'Social Security',
      sns: 'national health service',
      comments: 'Further information',
      dni: 'National ID card',
      nuss: 'Social Security number',
    },
    detail: {
      document: {
        firmdate: 'Date of signing',
        description: 'Description',
        sendDocument: 'Indicate e-mail address',
        success: 'The documents have been sent successfully',
        error: 'There was a problem sending the documents',
        view: 'View Saved Document',
        send: 'Send document',
        accepted: 'Accepted',
        declined: 'Refused',
      },
      pdf: {
        description: 'Add email addresses separated by commas.',
        success: 'The report has been successfully submitted',
        errorSend: 'An error occurred while sending the report',
      },
      tugInformation: 'TUG Info',
      BilanTug: 'TUG test',
      subTabs: {
        tabGraphique: 'Chart',
        tabIndicators: 'Indicators',
        tabBalance: 'Balance',
      },
      tabObservations: {
        observation: 'Comments',
        allDates: 'All dates',
      },
      tabSmartPredict: {
        cardTab: {
          description: 'Description',
          interpretation: 'Interpretation',
          recomendation: 'Recommendation',
        },
        stadistic: {
          fragilite: {
            bad: 'Frail',
            stable: 'Pre-frail',
            good: 'Non frail',
          },
          equilibre: {
            bad: 'Unstable',
            good: 'Steady',
          },
          risqueChute: {
            bad: 'High',
            good: 'Low',
          },
        },
        fragility: 'Fragility',
        marcha: 'Walk',
        psychological: 'Scores',
        physiologique: 'Physio Assessment',
        name: 'SmartPredict',
        dataForDate: 'Viewing data from',
        impedance: "Bioimpedance",
        pdf: {
          sentPdf: 'Send PDF',
          downloadPdf: 'Download PDF',
          documentOption: `Document options`,
          IheXdmReport: `IHE XDM Report`,
          smartPredictReport: `SmartPredict Report`,
          seePdf: `See PDF`,
        },
        firtsIndicators: {
          time_TUG: 'Total TUG time',
          walk_aller_plus_retour: 'Walking time',
          number_of_steps_aller_retour: 'Number of steps',
          risque_TUG: 'Risk of fall',
        },
        graphic: {
          labelDates: 'dates',
          labelValue: 'Value',
          labelLoadingText: 'Loading...',
        },
      },
      tabInformation: {
        name: 'General Information',
        openEyes: 'Open eyes',
        closeEyes: 'Eyes closed',
        notInformation: 'No indicator available',
        mainDoctor: 'Doctor',
        years: 'years',
        vdsi: 'Bilans VDSI',
        teleExpertise: 'Tele expertise'
      },
      tabSmartDetect: {
        tab: 'SmartDetect',
        openEyes: 'open your eyes',
        closeEyes: 'Eyes closed',
        cardsIndicators: {
          pressionForce: 'grip strength',
          maxLeft: 'Max Left',
          maxRight: 'Max Right',
          WeightDistribution: 'Weight distribution',
        },
      },
      tabTip: {
        tips: 'Tips',
        enterTheSearchText: 'Enter the search text',
        noResults: 'No results',
        simple: 'Single Board',
        pack: 'Tips packet',
        specialty: 'Specialty',
        configurationList: 'Configuration list',
        programmingWasSuccessfullyCreated:
          'Programming was successfully created',
        modal: {
          username: 'Username',
          password: 'Password',
          login: 'Login Certipair',
          incorrectPasswordUsernamePlease:
            'Incorrect password and/or username, please log in again.',
        },
        form: {
          now: 'Now',
          certifyThatHaveObtained:
            "I certify that I have obtained the patient's consent to send health messages by SMS via the CertiPair application.",
        },
        buttons: {
          hideAllMessages: 'Hide all messages',
          viewMessages: 'View messages',
        },
        tabOne: {
          adviceAssociatedWithThisPatient:
            'Advice associated with this patient',
          newCouncil: 'New Council',
          message: 'Message',
          status: 'Status',
          startDate: 'Date of creation ',
          endDate: 'Date of shipment ',
          sent: 'Sent',
          canceled: 'Canceled',
        },
        tapTwo: {
          and: 'and',
          hours: 'hours',
          deliveryTime: 'Delivery time',
          packageDetails: 'Package details',
          messagesIn: 'messages in',
          days: 'days',
          months: 'Months',
          webLink: 'Web link',
          selectTheMessages: 'Select the messages',
          all: 'All',
          myFavorites: 'My favorites',
          professions: 'Professions',
          noAdviceFound: 'No advice found',
          tip: 'Council',
          attachment: 'Attachment',
          whenDoYouWantToSendThisMessage:
            'When do you want to send this message?',
          selectDate: 'Select a date',
          selectTime: 'Select a time',
          schedule: 'Schedule',
          backToListOfMessages: 'Back to list of messages',
        },
      },
    },
    back: 'Return',
    next: 'Following',
    finish: 'To finish',
  },
  validators: {
    pdfIsNotValidate: 'PDF version is not supported',
    zipCode: 'The zip code does not belong to any community',
    zipCodeNotAssociate: 'No postal code has been found for the department',
    invalidZipCode: 'The zip code is invalid',
    emplyEmail: 'Email is required',
    emplyPassword: 'Password required',
    emplyCodeGoogle: 'Google Authenticator code is required',
    invalidEmail: 'Invalid email format',
    invalidMacAddress: 'Invalid MAC address',
    emailRegistered: 'The email is already registered',
    required: 'Required fields',
    jsonIsNotValidate: "The JSON format is not valid",
    number: 'The field must be numeric',
    emailExist: 'The email address already exists',
    invalidPhone: 'Enter a valid phone number',
    phoneExist: 'The phone already exists',
    addressExist: 'The address already exists',
    invalidHeight: 'The height must be between 1 and 999 Cm',
    invalidWeight: 'The weight must be between 1 and 999 Kg',
    license: 'The license number is invalid',
    name: 'The name is not valid',
    lastname: 'Last name is not valid',
    image: 'Invalid image format',
    minLength: 'The field must contain',
    num: 'figures',
    characters: 'Characters',
    imageSize: 'The maximum size of the image must be 1 MB.',
    invalidFormat: 'The format is not valid',
    undefinedLatitudeAndLongitude: 'Undefined latitude and longitude',
  },
  alt: {
    logoIdp: 'I.D.P. Logo',
    welcomeIdp: 'Welcome IDP Health',
  },
  languages: {
    french: 'French',
    portuguese: 'Portuguese',
    spanish: 'Spanish',
    english: 'English',
    spanishCo: "Colombian"
  },
}

export default en

const fr = {
  general: {
    download: "Télécharger",
    images: 'Images',
    dataDowload: 'Telechargement Des Données',
    dataGeneralDowload: 'Données du questionnaire',
    evolutionChart: "Courbe d'évolution",
    downloadCsv: 'Télécharger le fichier CSV',
    generateFile: 'Générer un fichier',
    noOptions: "Pas d'options",
    dateLocale: 'fr-FR',
    dateFormat: 'dd-MM-yyyy',
    timeFormat: 'HH:mm',
    dateTimeFormat: 'dd-MM-yyyy hh:mm aa',
    dateFormatGeneral: 'yyyy-MM-dd HH:mm:ss',
    dataNotFound: 'Indisponible',
    dateOfResponse: 'Date de réponse',
    search: 'Recherche',
    selectDate: 'Sélectionnez',
    secondsUnit: 's',
    angleUnit: '°',
    stepUnit: 'pas',
    mostRecent: 'Les plus récentes',
    name: 'Nom',
    notFound: 'Information non trouvée',
    actions: 'Actions',
    copyright: 'IDPSanté - © Copyright tous droits réservés',
    notFound404: "La page que vous recherchez n'a pas été trouvée...",
    notFound401: 'Veuillez vous reconnecter pour des raisons de sécurité',
    error404: 'Page not found',
    error401: 'La session a expiré',
    moreInfo: "Voir plus d'information",
    welcome: 'Bienvenue ',
    question: 'Question',
    lastUpdate: 'Dernière mise à jour',
    sendEmail: 'Envoyer un e-mail',
    total: 'Total',
    right: 'Droit',
    left: 'Gauche',
    fragilityFried: 'Fragilité FRIED',
    fragilityIndicators: 'Indicateurs de fragilité',
    bilanMarche: 'Marche',
    physiologique: 'Physiologique',
    psychologique: 'Psychologique',
    placeholderMedical: 'Recherche par nom de médecin',
    preview: 'Prévisualisation',
    score: 'Score',
    response: 'Réponse',
    patientMenu: {
      name: 'Nom et prénom',
      lastName: 'Nom de famille',
      birthday: 'Date de naissance',
      sex: 'Genre',
      address: 'Adresse',
      phone: 'Numéro de téléphone',
      score: 'Score de fragilité',
      dateEnd: 'Date du dernier smartpredict',
      medical_staff: 'Le personnel médical',
      value: 'Réponse à la question',
    },
    fragilityScore: {
      warning: "Pré'fragile",
      danger: 'Fragile',
      good: 'Robuste',
      noScore: 'Pas de score',
    },
    gender: {
      male: 'Homme',
      female: 'Femme',
      gender: 'Sexe',
    },
  },
  components: {
    modal: {
      value: 'Valeur(*)',
      valueOptional: 'Valeur',
      key: 'Key(*)',
      children: 'Enfants',
    },
    refresh: 'Refresh',
    sendMyself: 'Envoyer le rapport à mon adresse électronique',
    status: 'Actif',
    warningAtention: 'Attention!',
    warningDelete:
      'Cette action va effacer la configuration des étapes suivantes, êtes-vous sûr de vouloir continuer ?',
    deleteMessage: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
    uploadImage: "Télécharger l'image",
    changeStatus: 'Le statut a été modifié avec succès',
    errorStatus: "Le statut n'a pas pu être modifié",
    required: 'Obligatoire',
    noRequired: 'Non obligatoire',
    ok: "D'accord",
    pickerPlaceholder: 'Sélectionner',
    cancel: 'Annuler',
    send: 'Envoyer',
    increment: 'Ajouter',
    delete: 'Supprimer',
    alert: 'Enregistrement créé avec succès',
    alertDelete: 'Enregistrement supprimé avec succès',
    deleteError: "Une erreur s'est produite dans l'application",
    alertEdit: 'Enregistrement modifié avec succès',
    update: 'Mise à jour',
    createItem: 'Ajouter un enregistrement',
    editItem: "Mise à jour de l'enregistrement",
    deleteItem: 'La suppression est définitive, vous souhaitez confirmer ?',
    main: 'Page principale',
    backLogin: 'Aller à la connexion',
    homeCard: 'Patients',
    back: 'Retour',
    next: 'Suivant',
    success: 'Demande traitée',
    yes: 'Oui',
    profileIcon: {
      myProfile: 'Mon profil',
      logout: 'Se déconnecter',
    },
    traductions: {
      missingTraductionTitle: 'Champs à traduire !',
      missingTraductiontext:
        'Tous les champs ne sont pas traduits. Êtes-vous sûr de vouloir envoyer ces informations ?',
    },
  },
  myProfile: {
    title: 'Mon profil',
    myInfo: 'Mon information',
    dataUpdated: 'Données actualisées',
    emailDirection: 'Adresse mail :',
    editInformation: "Modifier l'utilisateur",
    resetPasswordTitle: 'Restaurer le mot de passe',
    editProfileTitle: 'Modifier mes informations personnelles',
    resetPassword: 'Confirmer le nouveau mot de passe',
    inputs: {
      name: 'Prénom',
      nameCenter: 'Nom du centre médical',
      lastname: 'Nom',
      phone: 'Numéro de téléphone',
      birth: 'Date de naissance',
      birthCenter: 'Date de constitution',
    },
  },
  cruds: {
    healthCondition: {
      noData: 'Information non trouvée',
      addTranslate: 'Ajouter',
      editTranslate: 'Modifier',
      listItem: 'Liste des sous-catégories',
      missingItem: 'Traductions en cours',
      create: 'Nouvel état de santé',
      created: "L'état de santé a été créé",
      edited: 'État de santé modifié',
      edit: "Modifier l'état de santé",
      detail: "Détails de l'état de santé",
      generalInformation: 'Information générale',
      show: "Détails de l'état de santé",
      translate: 'Traduction',
      return: 'Retour à la liste des codes de santé',
      csv: 'Trouver un CSV',
      category: 'Nom de la catégorie principale *',
      subCategory: 'Sous-catégories *',
      instructions:
        'Vous pouvez ajouter autant de sous-catégories que vous le souhaitez. Séparez chaque sous-catégorie par une virgule ou faites simplement glisser et déposer un fichier CSV ici.',
      translateInstruction:
        'Vous pouvez taper chaque traduction sur la ligne correspondante, ou vous pouvez déposer un fichier CSV dans la boîte des traductions si vous préférez.',
    },
    smartTest: {
      statusSelector: "Sélecteur d'état",
      indicator: 'Cette étape génère-t-elle un indicateur ?',
      dataSourceId: 'Id de la source de données',
      enum: {
        create: 'Créer',
        update: 'Mise à jour',
        recordless: 'Sans enregistrement',
      },
      goToQuestions: 'Allez à la section des questions',
      questionarie: 'Questionnaire',
      smartQuestionnaire: 'Smart Questionnarie',
      kinvent_grip: 'K-Physio Grip',
      kinvent_gait: 'K-Physio Gait',
      kinvent_stance: 'K-Physio Stance',
      kinvent_singlemove: 'K-Physio SingleMove',
      kinvent_open: 'K-Physio Open',
      aminogram_test: "Test d'impédance",
      kinvent_gait_no_cal: "Kinvent gait no cal",
      kinvent_gait_three_no_cal: "Kinvent gait three no cal",
      xsens: 'X-sens',
      smartTest: 'Smart Test',
      kforceStrength: 'Kforce Strength',
      kforceBalance: 'Kforce Blance',
      teleconsultation: 'Téléconsultation',
      button: 'Ajouter un nouveau test',
      block: 'Bloc',
      tableheader: {
        title: 'Titre',
        description: 'Description',
        typeTest: 'Type de test',
        numberSensors: 'N Capteurs',
        typeProvider: 'Type de fournisseur',
        status: 'statut',
        actions: 'Actions',
        position: 'Position',
      },
      associateStaffType: {
        help: 'Sélectionnez les spécialisations qui seront en mesure de visualiser le smartTest, sinon toutes les spécialisations seront en mesure de visualiser le smartTest.',
        associate: 'Association du personnel ',
        returnList: 'Voltar à lista de testes inteligentes',
        success: 'Partenariat fructueux',
        ok: "L'association du personnel a été réalisée",
        smartTest: 'SmartTest',
      },
      form: {
        actionSelect: "Sélecteur d'action",
        name: 'Titre du SmartTest',
        updateProvider: 'Mettre à jour le fournisseur ?',
        idpApp: 'IDP-APP',
        idpSmart: 'IDP-SMART',
        providers: 'Fournisseur',
        sensorsLabel: 'Nombre de capteurs qui seraient utilisés',
        sensors: 'Nombre de capteurs',
        description: 'Description du SmartTest',
        blockType: 'Type de bloc',
        questionarieSelect: 'Choisissez un questionnaire',
        smartTestSelect: 'Choisissez un smart test',
        instructions: 'Instructions de bloc',
        instructionTest: 'Instructions pour le smart test',
        instructionSensors: 'Instructions pour les capteurs',
        thirdOption: 'Temps de réponse',
        icon: 'Icône test',
        position: "Position de l'ordre",
      },
      steps: {
        stepOne: { step: 'Étape 1', label: 'Information générale' },
        stepTwo: { step: 'Étape 2', label: 'Configuration du processus' },
        stepThree: { step: 'Étape 3', label: 'Définition des langues' },
        questions: {
          stepOne: { step: 'Étape 1', label: "Questions d'édition" },
          stepTwo: { step: 'Étape 2', label: 'Définition des langues' },
        },
      },
      create: {
        indicator: 'Nouveau SmartTest',
        title: 'Créer un nouveau SmartTest',
        subtitle: 'Nouveau SmartTest',
        newBlock: 'Ajouter un bloc',
        created: 'Le smart test a été créé avec succès',
        return: 'Retour à la liste des smart test',
      },
      edit: {
        viewSavedImage: "Voir l'image sauvegardée",
        viewImageInstructions: "Voir l'image pour les instructions",
        viewImageSensors: "Voir l'image des capteurs",
        instructional_picture: "Voir l'image pour les instructions",
        data_sensors: "Voir l'image des capteurs",
        indicator: 'Modifier un SmartTest',
        title: 'Modifier le smart test actuel',
        subtitle: 'Modifier un SmartTest',
        created: 'Le smart test a été modifié avec succès',
        return: 'Retour à la liste des smart test',
      },
      questions: {
        numberOfQuestions: 'Nombre de questions',
        indicator: 'Étapes',
        title: 'Liste des étapes',
        edit: {
          title: 'Edition du questionnaire',
          return: 'Retour à la liste des étapes',
        },
        form: {
          nameQuestionLabel: 'Titre de la question',
          typeofQuestionLabel: 'Type de question',
          option: 'Option',
        },
      },
      selectionSimple: 'Sélection simple',
      selectionMultiple: 'Sélection multiple',
      open: 'Question libre',
    },
    patients: {
      anonimusFieldsDownload: 'Télécharger les fichiers anonymisés',
      return: 'Retour à la liste des patients',
      button: 'Ajouter un Patient',
      steps: {
        stepOne: 'Étape 1',
        stepTwo: 'Étape 2',
      },
      labels: {
        stepOne: 'Information patient',
        stepTwo: 'Praticien référent et Personnel médical',
      },
      create: {
        indicator: 'Nouveau patient',
        title: 'Créer un nouveau patient',
        created: 'Le patient a été créé avec succès',
      },
      edit: {
        indicator: 'Modifier le patient',
        title: 'Modifier un patient',
        created: 'Le patient a été modifié avec succès',
      },
    },
    medicalCenters: {
      return: 'Retour à la liste des centres',
      htmlName: 'Nom du fichier html',
      identifiers: 'Identifiants',
      tableheader: {
        fullName: 'Nom',
        type: 'Type',
        email: 'Adresse mail',
        status: 'Statut',
        address: 'Adresse',
      },
      informationList: {
        info1: {
          title:
            "Le HTML doit correspondre uniquement au corps du document, l'en-tête et le pied de page seront ajoutés automatiquement.",
        },
        info2: {
          title:
            'Dans le HTML, vous pouvez inclure les balises suivantes, qui seront remplacées par les informations stockées dans notre plate-forme :',
          childs: {
            c1: 'nom complet du patient',
            c2: "l'adresse du patient",
            c3: "numéro d'identification du patient",
            c4: "la date d'expiration du document d'identification du patient",
            c5: 'la ville du centre médical',
            c6: 'la date à laquelle le document est signé',
            c7: 'la signature du patient',
          },
        },
        info3: {
          title: 'Le fichier HTML doit avoir un nom',
        },
      },
      createButton: 'Nouveau centre IDP',
      show: {
        indicator: 'Voir détails',
        titlePage: 'Voir les détails du centre IDP',
        informationTitle: 'Informations sur le centre',
        personalTitle: 'Personnel médical associé',
        contactTitle: 'Personnes de contact',
        noContacts: 'Aucun contact lié',
        back: 'Retourner',
        address: 'Adresse du centre',
      },
      create: {
        titlePage: 'Créer nouveau centre',
        addressSubtitle: "Ajouter l'adresse du centre",
        addressTitle: 'Adresse du centre',
        indicator: 'Nouveau centre',
        created: 'Le centre a été créé avec succès',
        licenseTitle: 'Ajouter le numéro de licence',
        licenseSubtitle: 'Numéro de licence',
        informationCenter: 'Ajouter des informations sur le centre',
        consentDocument: 'Document de consentement',
        confidentialDocument: 'Document confidentiel',
      },
      edit: {
        titlePage: "Modification d'un centre",
        addressSubtitle: 'Mise à jour du centre médical',
        addressTitle: 'Adresse du centre',
        indicator: 'Mise à jour centre',
        created: 'Le centre a été modifié avec succès',
        informationCenter: 'Mise à jour des informations sur le centre',
      },
      form: {
        name: 'Nom du centre',
        email: 'Adresse mail',
        phone: 'Numéro de téléphone',
        country: 'Pays',
        city: 'Ville',
        address: 'Adresse',
        typeCenter: 'Type de centre médical',
        license: 'Numéro de licence',
        updateLicense: 'Mise à jour du numéro de licence',
        nameOfTheConsentDocument: 'Nom du document de consentement',
        descriptionOfTheConsentDocument:
          'Description du document de consentement',
      },
      contacts: {
        button: 'Ajouter un contact',
        title: 'Ajouter des informations de contact',
        subtitle: 'Liste des contacts associés',
        form: {
          name: 'Prénom',
          lastname: 'Nom',
          email: 'E-mail',
          phone: 'Téléphone',
          fullName: 'Nom et prénom',
          speciality: 'Spécialité',
        },
      },
      sensors: {
        title: "Configuration d'un dispositif SmartPredict",
        detail: 'Dispositif SmartPredict',
        kit: 'Kit',
        subtitle: 'MAC des capteurs du kit',
        button: "Ajout d'un kit de capteurs",
        form: {
          sensor: 'Capteur',
        },
      },
      biody: {
        title: "Configuration d'un appareil Biody",
        biody: "Biody",
        titleShow: "Appareil Biody",
        button: "Ajouter un appareil",
        form: {
          id: "Dispositif",
          license: "licence"
        }
      }
    },
    users: {
      return: 'Retour à la liste des utilisateurs',
      tableheader: {
        fullName: 'Nom et prénom',
        rol: 'Rôle',
        age: 'Age',
        email: 'Adresse mail',
        verified: 'Vérifié',
      },
      crateButton: 'Créer un nouvel utilisateur',
      create: {
        titlePage: 'Créer un utilisateur',
        indicator: 'Nouvel utilisateur',
        created: "L'utilisateur a été créé avec succès",
        userInfoTitle: "Informations sur l'utilisateur",
        userPasswordTitle: "Création d'un mot de passe",
      },
      edit: {
        titlePage: 'Mettre à jour un utilisateur',
        indicator: "Modifier l'utilisateur",
        created: "L'utilisateur a été modifié avec succès",
      },
      inputs: {
        name: 'Prénom(*)',
        lastname: 'Nom(*)',
        phone: 'Numéro de téléphone(*)',
        birth: 'Date de naissance(*)',
        email: 'Adresse mail(*)',
        locale: 'Langue(*)',
      },
    },
    profiles: {
      return: 'Retour à la liste des bilans',
      list: {
        button: 'Nouveau bilan',
      },
      create: {
        indicator: 'Nouveau bilan',
        title: 'Nouveau bilan',
        step: {
          first: 'Étape 1',
          second: 'Étape 2',
          third: 'Étape 3',
        },
        stepLabel: {
          first: 'Information générale',
          second: 'Liste des indicateurs',
          third: 'Définition des langues',
        },
        nameLabel: 'Nom du bilan(*)',
        descriptionLabel: 'Description du bilan(*)',
        providerLabel: 'Fournisseur(*)',
        indicatorsSelection:
          'Sélectionnez les indicateurs que vous souhaitez ajouter à ce bilan',
        indicators: 'Indicateurs',
        indicatorsTitle: 'Lier les indicateurs',
        translateTitle: 'Veuillez traduire les textes suivants',
        created: 'Le bilan a été créé avec succès',
        translateLabel: 'Texte original',
        noImage: "Pas d'image d'instruction",
        textToTranslate: 'Texte à traduire',
        name: 'Nom',
        description: 'Description',
      },
      edit: {
        indicator: 'Mise à jour du bilan',
        title: 'Modifier le bilan',
        edited: 'Le bilan a été mis à jour avec succès',
      },
    },
    indicators: {
      return: 'Retour à la liste des indicateurs',
      list: {
        button: 'Nouvel indicateur',
      },
      create: {
        indicator: 'Nouvel indicateur',
        title: 'Créez un nouvel indicateur',
        step: {
          first: 'Étape 1',
          second: 'Étape 2',
          third: 'Étape 3',
        },
        stepLabel: {
          first: 'Information générale',
          second: 'Annotations spécifiques',
          third: 'Définition des langues',
        },
        nameLabel: "Nom de l'indicateur(*)",
        dataSourceLabel: 'Source des données(*)',
        descriptionLabel: "Description de l'indicateur(*)",
        frequencieLabel: 'Fréquence(*)',
        unitLabel: "Type d'unité(*)",
        acronymLabel: 'Acronyme(*)',
        recomendationLabel: 'Recommandation',
        interpretationLabel: 'Interprétation(*)',
        interpretation: 'Interprétation',
        valueLabel: 'Valeur',
        type: 'Type de données',
        indicatorsSelection:
          'Sélectionnez les indicateurs que vous souhaitez ajouter à ce bilan',
        indicators: 'Indicateurs',
        indicatorsTitle: 'Lier les indicateurs',
        translateTitle: 'Veuillez traduire les textes suivants',
        created: "L'indicateur a été créé avec succès",
        translateLabel: 'Texte original',
        textToTranslate: 'Texte à traduire',
        name: "Nom de l'indicateur",
        description: "Description de l'indicateur",
      },
      edit: {
        indicator: "Mise à jour d'un indicateur",
        title: "Mise à jour d'un indicateur",
        edited: "L'indicateur a été mis à jour avec succès",
      },
      type: {
        text: 'Texte',
        number: 'Numérique',
        image: 'Image',
        static: 'Statique',
        boolean: 'Booléen',
      },
      annotation: {
        button: 'Ajouter une recommandation',
      },
    },
    questionaries: {
      return: 'Retour à la liste des questionnaires',
      statusButtom: 'Changement',
      changeStatus: 'Voulez-vous changer le statut ?',
      selectionSimple: 'Sélection simple',
      selectionMultiple: 'Sélection multiple',
      numeric: 'Numérique',
      opened: 'Ouverte',
      label: 'Etiquette',
      option: 'Option',
      score: 'Score',
      list: {
        button: 'Nouveau questionnaire',
        status: 'Statut',
        questionarie: 'Questionnaire',
      },
      create: {
        indicator: 'Nouveau Questionnaire',
        title: 'Nouveau Questionnaire',
        addOption: 'Ajouter une question',
        step: {
          first: 'Étape 1',
          second: 'Étape 2',
          third: 'Étape 3',
        },
        stepLabel: {
          first: 'Information générale',
          second: 'Questions',
          third: 'Définition des langues',
        },
        nameLabel: 'Nom du questionnaire(*)',
        descriptionLabel: 'Instructions pour le questionnaire(*)',
        nameQuestionLabel: 'Titre de la question',
        typeofQuestionLabel: 'Type de question',
        name: 'Nom du questionnaire',
        description: 'Description',
        instructions: 'Instructions pour le questionnaire',
        created: 'Questionnaire créé avec succès',
      },
      edit: {
        indicator: 'Modifier le questionnaire',
        title: 'Modifier le questionnaire',
        created: 'Questionnaire modifié avec succès',
      },
    },
    units: {
      button: 'Ajouter une unité',
    },
    parameter: {
      button: 'Ajouter une paramètre',
      key: 'Key',
      value: 'Valeur',
      text: 'Texte',
      file: 'Fichier',
      json: 'Json',
    },
    typeCenter: {
      button: 'Ajouter un type de centre médical',
    },
    typeStaff: {
      button: 'Ajouter un type de personnel',
      personalType:
        'Le type de personnel sera-t-il affecté à un médecin traitant?',
      code: 'Code',
      codeSystem: 'Code System',
    },
    specialities: {
      button: 'Ajouter une spécialité',
    },
    relationships: {
      button: 'Ajouter une relation',
      medicalstaff: {
        button: 'Ajouter un personnel médical',
      },
    },
    providers: {
      button: 'Ajouter une fournisseur',
    },
    healthcondition: {
      button: 'Ajouter un état de santé',
    },
  },
  sidebar: {
    smartTest: 'SmartTest',
    dashboard: 'Tableau de bord',
    patients: 'Patients',
    groups: 'Groupes',
    mstaff: 'Personnel médical',
    mcenter: 'Centres IDP',
    users: 'Utilisateurs',
    configuration: 'Configuration',
    indicator: 'Indicateurs',
    questionaries: 'Questionnaires',
    frequencie: 'Fréquences',
    provider: 'Fournisseurs',
    profile: 'Bilans',
    relation: 'Relations',
    specialty: 'Spécialités',
    typestaff: 'Type de Personnel',
    typecenter: 'Type de Centre Médical',
    unities: 'Unités',
    healthcondition: 'Conditions de santé',
    homePredict: 'HomePredict',
    parameter: 'Paramètre',
    bilansMedicalStaff: 'Bilans medical staff',
  },
  breadcrumb: {
    smartTest: 'SmartTest',
    dashboard: 'Tableau de bord',
    units: 'Unités',
    patient: 'Patients',
    groupsPatient: 'Groupes de patients',
    groupNewAssociate: 'Nouveau groupe - Associé',
    mcenter: 'Centre médical',
    centerIDP: 'Centre IDP',
    typeStaff: 'Type Personnel',
    medicalStaff: 'Personnel médical',
    patients: 'Patients',
    newPatient: 'Nouveau patient',
    newStaff: 'Nouveau personnel médical',
    detailStaff: 'Détail du personnel médical',
    typeMedicalCenter: 'Type de centre médical',
    newcenterIDP: 'Nouveau Centre IDP',
    specialties: 'Spécialité',
    relationships: 'Relations',
    providers: 'Fournisseurs',
    healthConditions: 'Antécédents de santé connus (facultatif)',
    healthCondition: 'Etat de santé',
    frequencies: 'Fréquences',
    questionaries: 'Questionnaires',
    newQuestionaries: 'Nouveau questionnaire',
    detailQuestionarie: 'Détail du questionnaire',
    indicators: 'Indicateurs',
    newIndicators: 'Nouvel indicateur',
    detailIndicators: "Détails de l'indicateur",
    profiles: 'Bilan',
    newProfiles: 'Nouveau bilan',
    detailProfiles: 'Détail du bilan',
    editIndicatorsProfiles: 'Ajouter/supprimer des indicateurs',
    associateQuestionariesProfiles: 'Questionnaire Association',
    users: 'Utilisateurs',
    newUser: 'Nouvel utilisateur',
    detailUser: "Détail de l'utilisateur",
    add: 'Ajouter',
    edit: 'Editer',
    detail: 'Détails',
    homePredict: 'HomePredict',
    parameter: 'Paramètre',
  },
  form: {
    fieldEmail: 'Email',
    fieldPassword: 'Mot de passe',
    fieldConfirmPassword: 'Confirmer le mot de passe',
    fieldValidationPasswords: 'Les mots de passe ne sont pas identiques',
    fieldValidationSecuritypasswords:
      "Le mot de passe n'est pas conforme à la politique de sécurité",
    button: {
      actionSave: 'Sauvegarder',
    },
    searchBar: 'Saisir le texte de recherche',
  },
  auth: {
    name: 'IDP Santé',
    titleLogin: 'S’identifier',
    authTwoFactor: 'Authentification à deux facteurs',
    authTwilio: 'Authentification par SMS',
    placeholderCode: 'Saisir le code',
    placeholderUsername: "Nom d'utilisateur ou email",
    placeholderPassword: 'Mot de passe',
    placeholderForgetPassword: 'Adresse mail',
    forgetPassSubTitle: 'Mot de passe oublié ?',
    forgetPassDescription:
      'Renseignez votre email et nous vous enverrons un lien de réinitialisation.',
    forgetPassSuccessText:
      'Nous vous avons envoyé un email contenant un lien pour réinitialiser votre mot de passe.',
    invalidData: "les informations d'identification sont invalides",
    invalidcenter:
      'Vous ne pouvez actuellement pas vous connecter, veuillez contacter votre administrateur.',
    invalidCode: 'Code Google Authenticator invalide',
    invalidSmsCode: 'Code SMS invalide',
    codeWasSend: 'Un nouveau code a été envoyé par SMS',
    codeWasNotSend:
      "Une erreur s'est produite lors de l'envoi du code, veuillez réessayer plus tard.",
    sendRequest: 'Sauvegarder',
    reutnToLogin: "Retour à la page de connexion",
    cancelRequest: 'Annuler',
    resetPassSubTitle: 'Réinitialiser votre mot de passe',
    loginMethod: 'Méthode de connexion',
    loginMethodSelected:
      "Sélectionnez la méthode à utiliser pour l'authentification du compte",
    phoneNumber: 'Numéro de téléphone',
    resetPassDescription: 'Entrez un nouveau mot de passe et confirmez.',
    enterValidatePhoneNumber:
      "Saisissez un numéro de téléphone valide qui vous enverra le code d'authentification.",
    resetPassSave: 'Sauvegarder',
    signInButton: 'Se connecter',
    tryAgain: 'Réessayez',
    resendCode: 'Renvoyer le code',
    changeNumber: 'Changement de numéro de téléphone',
    signInForgotPass: 'Vous avez oublié votre mot de passe ?',
    signUpTermsConditions: "J'accepte les conditions générales",
    signUpButton: "S'inscrire",
    signUpAlreadyAccount: 'Avez-vous déjà un compte ? Connectez-vous.',
    textNeedChangePassword:
      'Votre mot de passe est expiré. Pour des raisons de sécurité, il est nécessaire de changer le mot de passe.',
    textLinkChangePassword: 'Changer le mot de passe',
    textGenerateNewPassword:
      'Pour continuer, un lien a été envoyé à votre adresse électronique pour générer le mot de passe.',
    textOne2Factor:
      "Scannez le QRcode avec l'application Google Authenticator de votre téléphone.",
    textTwo2Factor:
      'Le code Google Authenticator pour IDP SANTE apparaît dans la liste.',
    textTree2Factor:
      "Si vous ne parvenez pas à scanner le code, vous pouvez saisir manuellement la clé de configuration dans l'application Google Authenticator:",
    textFour2Factor:
      'Pour les connections suivantes, vous pourrez directement utiliser les codes générés par Google Authenticator.',
    textEmailSend: 'Envoyer',
    textEmailReSend: 'Renvoyer',
    textEmailCancel: 'Annuler',
    textAttention: 'Attention',
    textSliderPasswordWeak: 'Faible',
    textSliderPasswordStrong: 'Fort',
    textSecurityPoliciesTitle: 'Le mot de passe doit contenir',
    textSecurityPoliciesRule1: '- 8 caractères ou plus',
    textSecurityPoliciesRule2:
      '- Au moins une lettre majuscule et une lettre minuscule',
    textSecurityPoliciesRule3:
      '- Au moins un chiffre et un caractère spécial sont autorisés @$!%*?&',
    buttonTextBackLogin: 'Retour à la page de connexion',
    sendLinkSuccess: 'Lien envoyé sur votre boite mail',
    alert: 'Attention',
    oldPasswordAlert:
      'Mot de passe invalide. Le mot de passe doit être différent des trois derniers utilisés.',
    resetPassSuccess: 'Mise à jour du mot de passe réussie',
    SMS: 'SMS',
    authenticator: 'Authentificateur Google',
  },
  homePredict: {
    title: 'HomePredict',
    errorUpdatePatient: 'Le patient ne peut pas être mis à jour pour le moment',
    errorUpdatePatients:
      "Il n'est actuellement pas possible de mettre à jour les patients",
  },
  medicalstaff: {
    add: 'Ajouter du personnel médical',
    edit: 'Editeur du personnel médical',
    editShort: 'Editeur',
    save: 'Sauver',
    back: 'Retourner',
    personalInformation: 'Informations sur le type de personnel',
    treatingPhysician: 'Médecin traitant',
    tableheader: {
      fullName: 'Nom et prénom',
      type: 'Type',
      address: 'Adresse',
      specialty: 'Spécialité',
      email: 'Adresse email',
      centersIDP: 'Centres IDP',
      status: 'Statut',
    },
    form: {
      return: 'Retour à la liste du personnel médical',
      created: 'Le personnel médical a été créé avec succès',
      edited: 'Le personnel médical a été modifié avec succès',
      first_name: 'Prénom',
      last_name: 'Nom de famille',
      date_of_birth: 'Date de naissance',
      gender: 'Genre',
      mail_address: 'Adresse mail',
      phone_number: 'Numéro de téléphone',
      medical_center: 'Centre médical',
      staff_type: 'Type de personnel',
      speciality: 'Spécialité',
      age: 'Age',
      codeInsee: 'Lieu de naissance',
      code: 'Code',
      codeSystem: 'Code System',
      rpps: 'RPPS',
    },
    show: {
      personalInfo: 'Informations personnelles',
      professionalInfo: 'Informations professionnelles',
      male: 'Homme',
      female: 'Femme',
    },
  },
  patient: {
    title: 'Liste générale des patients',
    teleExpertise: {
      selectAll: "Tout sélectionner",
      unselectAll: "Désélectionner tout",
      submit: "Envoyer la demande",
      request: {
        success: 'La demande a été soumise avec succès',
        error: "Une erreur s'est produite lors de l'envoi de la demande"
      },
      youHave: 'Vous avez',
      selectedFiles: 'fichiers sélectionnés',
      reason: {
        title: 'Motif de la demande',
      },
      selects: {
        title: 'Sélectionnez les fichiers que vous souhaitez joindre',
        smartPredict: 'Rapport Smartpredict',
        right_ear: "Images de l'oreille droite",
        left_ear: "Images de l'oreille gauche",
        extended: 'Audio Cœur / Poumons',
        lungs: 'Audio Poumons',
        heart: 'Coeur audio',
        vdsi: 'Rapport de la VDSI',
      },
    },
    tableheader: {
      type: 'Type',
      speciality: 'Spécialité',
      fullName: 'Nom et prénom',
      name: 'Nom',
      gender: 'Genre',
      age: 'Age',
      fragilityScore: 'Score de fragilité',
      fragility: 'Date du prochain rdv',
      stability: 'Indice de Stabilité',
      status: 'Actif',
      vdsi: 'SLIMS',
    },
    form: {
      heart: 'Coeur',
      lungs: 'Poumon',
      extended: 'Coeur/Poumon',
      takePhoto: 'Prenez une photo',
      name: 'Prénom (*)',
      validate_to: "Valable jusqu'au",
      observation: 'Commentaires',
      lastname: 'Nom de famille (*)',
      birth: 'Date de naissance (*)',
      sex: 'Genre (*)',
      left_ear: 'Oreille gauche',
      right_ear: 'Oreille droite',
      gender: {
        female: 'Féminin',
        male: 'Masculin',
      },
      hands: {
        right: 'Droitier',
        left: 'Gaucher',
      },
      height: 'Taille Cm (*)',
      weight: 'Poids Kg (*)',
      right_hand: 'Main (*)',
      place_birth: 'Pays de naissance(*)',
      address: 'Adresse(*)',
      email: 'Adresse mail (*)',
      phone: 'Numéro de téléphone (*)',
      SSN: 'Numéro de Sécurité Sociale',
      zipCode: 'Code postal',
      center_id: 'Centre médical (*)',
      health: 'Etat de santé',
      codeInsee: 'Code Insee',
      date: 'Filtrer par date',
      searchFilter: 'Recherche de filtres',
      startDate: 'Date de début',
      endDate: 'Date finale',
      clearDate: 'Nettoyer les filtres',
      department: 'Département',
      community: 'Communautés',
      cc: 'CC - Numéro de la carte de citoyen',
      nif: "NIF - Numéro d'identification fiscale",
      niss: 'NISS - Numéro de sécurité sociale',
      sns: "SNS - Nº d'utilisateurs SNS",
      comments: 'Informations complémentaires',
      dni: "DNI - Carte d'identité nationale",
      nuss: 'NUSS - Numéro de sécurité sociale',
    },
    hands: {
      right: 'Main droite',
      left: 'Main gauche',
    },
    show: {
      personalInfo: 'Information personnelle',
      contactInfo: 'Information de contact',
      healthInfo: 'Conditions de santé',
      medicalInfo: 'Personnel médical',
      principalDoctor: 'Médecin référent',
      documents: "Note d'information",
      observation: 'Commentaires',
      add: 'ajouter',
      update: 'mise à jour',
    },
    patientInfo: {
      email: 'Adresse mail',
      phone: 'Numéro de téléphone',
      birth: 'Date de naissance',
      address: 'Adresse',
      country: 'Pays',
      hand: 'Main dominante',
      weight: 'Poids',
      height: 'Taille',
      SSN: 'Numéro de Sécurité Sociale',
      male: 'Homme',
      female: 'Femme',
      medicalCenter: 'Centre médical',
      sex: 'Genre',
      department: 'Département',
      community: 'Ville',
      cc: 'Carte de citoyen',
      nif: 'Identification fiscale',
      niss: 'Sécurité sociale',
      sns: 'Service national de santé',
      comments: 'Informations complémentaires',
      dni: "Carte d'identité nationale",
      nuss: 'Numéro de sécurité sociale',
    },
    detail: {
      document: {
        firmdate: 'Date de la signature',
        description: 'Description',
        sendDocument: "Indiquez l'adresse e-mail",
        success: 'Les documents ont été envoyés avec succès',
        error: 'Il y a eu un problème pour envoyer les documents',
        view: 'Voir le document enregistré',
        send: 'Envoyer le document',
        accepted: 'Accepté',
        declined: 'Refusé',
      },
      pdf: {
        description:
          'Ajoutez les adresses électroniques séparées par des virgules.',
        success: 'Le rapport a été soumis avec succès',
        errorSend: "Une erreur s'est produite lors de l'envoi du rapport",
      },
      tugInformation: 'TUG Info',
      BilanTug: 'Bilan TUG',
      subTabs: {
        tabGraphique: 'Graphique',
        tabIndicators: 'Indicateurs',
        tabBalance: 'Équilibre',
      },
      tabObservations: {
        observation: 'Commentaires',
        allDates: 'Toutes les dates',
      },
      tabSmartPredict: {
        cardTab: {
          description: 'Description',
          interpretation: 'Interprétation',
          recomendation: 'Recommandation',
        },
        stadistic: {
          fragilite: {
            bad: 'Fragile',
            stable: "Pré'fragile",
            good: 'Robuste',
          },
          equilibre: {
            bad: 'Instable',
            good: 'Stable',
          },
          risqueChute: {
            bad: 'Élevé',
            good: 'Faible',
          },
        },
        fragility: 'Fragilité',
        marcha: 'Marche',
        psychological: 'Scores',
        physiologique: 'Bilan Physio',
        name: 'SmartPredict',
        dataForDate: 'Affichage des données de',
        impedance: "Bioimpédance",
        pdf: {
          sentPdf: 'Envoyer PDF',
          downloadPdf: 'Télécharger PDF',
          documentOption: `Options du document`,
          IheXdmReport: `Rapport IHE XDM`,
          smartPredictReport: `Rapport SmartPredict`,
          seePdf: `Voir le PDF`,
        },
        firtsIndicators: {
          time_TUG: 'Temps total TUG',
          walk_aller_plus_retour: 'Temps de Marche',
          number_of_steps_aller_retour: 'Nombre de pas',
          risque_TUG: 'Risque de chute',
        },
        graphic: {
          labelDates: 'Dates',
          labelValue: 'Valeur',
          labelLoadingText: 'Chargement...',
        },
      },
      tabInformation: {
        name: 'Information générale',
        openEyes: 'Les yeux ouverts',
        closeEyes: 'Les yeux fermés',
        notInformation: 'Aucun indicateur disponible',
        mainDoctor: 'Médecin traitant',
        years: 'ans',
        vdsi: 'Bilans VDSI',
        teleExpertise: 'Télé expertise',
      },
      tabSmartDetect: {
        tab: 'SmartDetect',
        openEyes: 'Yeux ouverts',
        closeEyes: 'Yeux fermés',
        cardsIndicators: {
          pressionForce: 'Force de Préhension',
          maxLeft: 'Max Gauche',
          maxRight: 'Max Droite',
          WeightDistribution: 'Distribution de poids',
        },
      },
      tabTip: {
        tips: 'Conseils',
        enterTheSearchText: 'Saisir le texte de la recherche',
        noResults: 'Pas de résultats',
        simple: 'Conseil unique',
        pack: 'Paquet de conseils',
        specialty: 'Spécialité',
        configurationList: 'Liste des configurations ',
        programmingWasSuccessfullyCreated:
          'La programmation a été créée avec succès',
        modal: {
          username: "Nom d'utilisateur",
          password: 'Mot de passe',
          login: 'Login Certipair',
          incorrectPasswordUsernamePlease:
            "Mot de passe et/ou nom d'utilisateur incorrect, veuillez vous connecter à nouveau",
        },
        form: {
          now: 'Maintenant',
          certifyThatHaveObtained:
            "Je certifie que j'ai bien obtenu l'accord du patient pour lui envoyer des messages de santé par SMS via l'application CertiPair",
        },
        buttons: {
          hideAllMessages: 'Cacher tous les messages',
          viewMessages: 'Voir les messages',
        },
        tabOne: {
          adviceAssociatedWithThisPatient: 'Conseils associés à ce patient',
          newCouncil: 'Nouveau Conseil',
          message: 'Message',
          status: 'Statut',
          startDate: 'Date de création',
          endDate: "Date d'envoi",
          sent: 'Envoyé',
          canceled: 'Annulé',
        },
        tapTwo: {
          and: 'et',
          hours: 'heures',
          deliveryTime: 'Délai de livraison',
          packageDetails: 'Détails du paquet',
          messagesIn: 'messages sur',
          days: 'jours',
          months: 'Mois',
          webLink: 'Lien web',
          selectTheMessages: 'Sélectionner les messages',
          all: 'Tous',
          myFavorites: 'Mes favoris',
          professions: 'Professions',
          noAdviceFound: "Aucun avis n'a été trouvé",
          tip: 'Le Conseil',
          attachment: 'Pièce jointe',
          whenDoYouWantToSendThisMessage:
            'Quand voulez-vous envoyer ce message ?',
          selectDate: 'Sélectionnez une date',
          selectTime: 'Sélectionner une heure',
          schedule: 'Calendrier',
          backToListOfMessages: 'Retour à la liste des messages',
        },
      },
    },
    back: 'Retour',
    next: 'Suivant',
    finish: 'Finir',
  },
  validators: {
    pdfIsNotValidate: "La version PDF n'est pas prise en charge",
    zipCode: "Le code postal n'appartient à aucune ville",
    zipCodeNotAssociate:
      "Aucun code postal associé au département n'a été trouvé",
    invalidZipCode: "Le code postal n'est pas valide",
    emplyEmail: 'L e-mail est requis',
    emplyPassword: 'Mot de passe requis',
    emplyCodeGoogle: 'Le code Google Authenticator est requis',
    invalidEmail: "Format d'email invalide",
    invalidMacAddress: 'Adresse MAC invalide',
    emailRegistered: 'Le mail est déjà enregistré',
    required: 'Champs requis',
    jsonIsNotValidate: "Le format JSON n'est pas valide",
    number: 'Le champ doit être numérique',
    emailExist: "L'adresse mail existe déjà",
    invalidPhone: 'Entrez un numéro de téléphone valide',
    phoneExist: 'Le téléphone existe déjà',
    addressExist: "L'adresse existe déjà",
    invalidHeight: 'La hauteur doit être comprise entre 1 et 999 Cm',
    invalidWeight: 'Le poids doit être compris entre 1 et 999 Kg',
    license: "La licence n'est pas valide",
    name: "Le nom n'est pas valide",
    lastname: "Le nom de famille n'est pas valide",
    image: "Format d'image non valide",
    minLength: 'Le champ doit contenir',
    num: 'chiffres',
    characters: 'Caractères',
    imageSize: "Le poids maximal de l'image doit être de 1 Mo.",
    invalidFormat: "Le format n'est pas valide",
    undefinedLatitudeAndLongitude: 'Latitude et longitude non définies ',
  },
  alt: {
    logoIdp: 'Logo IDP',
    welcomeIdp: 'Bienvenue IDP Santé',
  },
  languages: {
    french: 'Français',
    portuguese: 'Portugais',
    spanish: 'Espagnol',
    english: 'Anglais',
    spanishCo: "Colombien"
  },
}

export default fr

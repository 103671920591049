const pt = {
  general: {
    download: 'Descarregar',
    images: 'Imagens',
    dataDowload: 'Download De Dados',
    dataGeneralDowload: 'Dados do questionário',
    evolutionChart: 'Curva de Evolução',
    downloadCsv: 'Descarregar ficheiro CSV',
    generateFile: 'Gerar ficheiro',
    noOptions: 'Sem opções',
    dateLocale: 'pt-PT',
    dateFormat: 'dd-MM-yyyy',
    timeFormat: 'HH:mm',
    dateTimeFormat: 'dd-MM-yyyy hh:mm aa',
    dateFormatGeneral: 'yyyy-MM-dd HH:mm:ss',
    dataNotFound: 'N/D',
    dateOfResponse: 'Data de resposta',
    search: 'Pesquisar',
    selectDate: 'Seleccione',
    secondsUnit: 's',
    angleUnit: 'º',
    stepUnit: 'Passos',
    mostRecent: 'Mais recentes',
    name: 'Nome',
    notFound: 'Informação não encontrada',
    actions: 'Acções',
    copyright: 'IDPSanté - © Copyright todos os direitos reservados',
    notFound404: 'A página que procura não foi encontrada...',
    notFound401: 'Por razões de segurança, faça login novamente',
    error404: 'Page not found',
    error401: 'Sessão expirou',
    moreInfo: 'Ver mais informação',
    welcome: 'Bem-vindo  ',
    question: 'Pergunta',
    lastUpdate: 'Última actualização',
    sendEmail: 'Enviar um e-mail',
    total: 'Total',
    right: 'Certo',
    left: 'Esquerda',
    fragilityFried: 'Frito frágil',
    fragilityIndicators: 'Indicadores de fragilidade',
    bilanMarche: 'Marche',
    physiologique: 'Physiologique',
    psychologique: 'Psicologia',
    placeholderMedical: 'Pesquisa por nome médico',
    preview: 'Pré-visualização',
    score: 'Pontuação',
    response: 'Réponse',
    patientMenu: {
      name: 'Nome completo',
      lastName: 'Apelido',
      birthday: 'Fecha de nacimiento',
      sex: 'Género',
      address: 'Endereço',
      phone: 'Número de telefone',
      score: 'Pontuação de fragilidade',
      dateEnd: 'Data do último smartpredict',
      medical_staff: 'Pessoal médico',
      value: 'Resposta à pergunta',
    },
    fragilityScore: {
      warning: "Pre'frágil",
      danger: 'Frágil',
      good: 'Robusto',
      noScore: 'Pontuação do pecado',
    },
    gender: {
      female: 'Feminino',
      male: 'Homem',
      gender: 'Género',
    },
  },
  components: {
    modal: {
      value: 'Valor(*)',
      valueOptional: 'Valor',
      key: 'Key(*)',
      children: 'Crianças',
    },
    refresh: 'Actualizar',
    warningAtention: 'Atenção!',
    warningDelete:
      'Esta acção irá limpar a configuração dos passos seguintes, tem a certeza que quer continuar?',
    deleteMessage: 'Tem a certeza de que quer remover este elemento?',
    uploadImage: 'Carregar imagem',
    viewImage: 'Ver imagem',
    sendMyself: 'Enviar um e-mail para o meu endereço de e-mail',
    status: 'Estado',
    changeStatus: 'O estatuto foi alterado com sucesso',
    errorStatus: 'O estatuto não pôde ser alterado',
    required: 'Obrigatório',
    noRequired: 'Não é necessário',
    ok: 'OK',
    pickerPlaceholder: 'Seleccionar',
    cancel: 'Cancelar',
    send: 'Enviar',
    delete: 'Eliminar',
    increment: 'Acrescentar',
    alertEdit: 'ERegisto modificado com sucesso',
    alert: 'Registo criado com sucesso',
    alertDelete: 'Registo eliminado com sucesso',
    deleteError: 'Ocorreu um erro no pedido',
    update: 'Actualização',
    createItem: 'Acrescentar um registo',
    editItem: 'Actualização do registo',
    deleteItem: 'Quer eliminar este registo?',
    main: 'Página principal',
    backLogin: 'Vá para o login',
    homeCard: 'Atrás',
    back: 'Retour',
    next: 'Seguinte',
    success: 'Aplicação processada',
    yes: 'Sim',
    profileIcon: {
      myProfile: 'O meu perfil',
      logout: 'Desconectar',
    },
    traductions: {
      missingTraductionTitle: 'Campos ainda por traduzir!',
      missingTraductiontext:
        'Há ainda alguns campos que não estão traduzidos. Tem a certeza de que quer enviar esta informação? ',
    },
  },
  myProfile: {
    title: 'Meu perfil',
    myInfo: 'A minha informação',
    dataUpdated: 'Dados actualizados',
    emailDirection: 'Endereço de e-mail:',
    editInformation: 'Mudar de utilizador',
    resetPasswordTitle: 'Restaurar palavra-passe',
    editProfileTitle: 'Alterar os meus dados pessoais',
    resetPassword: 'Confirmar nova senha',
    inputs: {
      name: 'Nome próprio',
      nameCenter: 'Nome do centro médico',
      lastname: 'Apelido',
      phone: 'Número de telefone',
      birth: 'Data de nascimento',
      birthCenter: 'Data de constituição',
    },
  },
  cruds: {
    healthCondition: {
      noData: 'Informação não encontrada',
      addTranslate: 'Adicionar',
      editTranslate: 'Editar',
      listItem: 'Lista de subcategorias',
      missingItem: 'Traduções em curso',
      create: 'Nuevo healt condition',
      edit: 'Editar estado de saúde',
      created: 'O estado de saúde foi criado',
      edited: 'Estado de saúde editado',
      detail: 'Detalhes sobre o estado de saúde',
      show: 'Detalhes do estado de saúde',
      generalInformation: 'Informação geral',
      translate: 'Traduzir',
      csv: 'Encontrar um CSV',
      return: 'Voltar à lista de códigos de saúde',
      category: 'Nome da categoria principal *',
      subCategory: 'Subcategorias *',
      instructions:
        'Pode adicionar tantas subcategorias quantas precisar. Separe cada subcategoria usando uma vírgula ou simplesmente arraste e largue um ficheiro CSV aqui',
      translateInstruction:
        'Pode Digitar cada tradução na linha respectiva, ou se preferir pode deixar cair um ficheiro CSV na caixa de traduções',
    },
    smartTest: {
      statusSelector: 'Selector de estado',
      indicator: 'Este passo gera um indicador?',
      dataSourceId: 'ID da fonte de dados',
      enum: {
        create: 'Criar',
        update: 'Actualização',
        recordless: 'Sem registo',
      },
      goToQuestions: 'Ir para a secção de perguntas',
      questionarie: 'Questionário',
      smartQuestionnaire: 'Smart Questionnarie',
      kinvent_grip: 'K-Physio Grip',
      kinvent_gait: 'K-Physio Gait',
      kinvent_stance: 'K-Physio Stance',
      kinvent_singlemove: 'K-Physio SingleMove',
      kinvent_open: 'K-Physio Open',
      aminogram_test: 'Teste de impedância',
      kinvent_gait_no_cal: "Kinvent gait no cal",
      kinvent_gait_three_no_cal: "Kinvent gait three no cal",
      xsens: 'X-sens',
      smartTest: 'Smart Test',
      kforceStrength: 'Kforce Strength',
      kforceBalance: 'Kforce Blance',
      teleconsultation: 'Teleconsulta',
      button: 'Adicionar um novo teste',
      block: 'Bloco',
      tableheader: {
        title: 'Título',
        description: 'Descrição',
        typeTest: 'Tipo de teste',
        numberSensors: 'N Sensores',
        typeProvider: 'Tipo de fornecedor',
        status: 'estado',
        actions: 'Acções',
        position: 'Posição',
      },
      associateStaffType: {
        help: 'Seleccione as especializações que poderão ver o teste smartTest, caso contrário todas as especializações poderão ver o teste smartTest.',
        associate: 'Associação do Pessoal',
        returnList: 'Voltar à lista',
        success: 'Parceiro bem sucedido',
        ok: 'A associação do pessoal foi levada a cabo',
        smartTest: 'SmartTest',
      },
      form: {
        actionSelect: 'Selecção da acção',
        name: 'Título do SmartTest',
        updateProvider: 'Actualizar o fornecedor?',
        idpApp: 'IDP-APP',
        idpSmart: 'IDP-SMART',
        providers: 'fournisseurs',
        sensorsLabel: 'Número de sensores que seriam utilizados',
        sensors: 'Número de sensores',
        description: 'Descrição do SmartTest',
        blockType: 'Tipo de bloco',
        questionarieSelect: 'Escolha um questionário',
        smartTestSelect: 'Escolha um smart test',
        instructions: 'Instruções de bloco',
        instructionTest: 'Instruções para o teste smart test',
        instructionSensors: 'Instruções para sensores',
        thirdOption: 'Tempo de resposta',
        icon: 'Ícone do teste',
        position: 'Posição da encomenda',
      },
      steps: {
        stepOne: { step: 'Passo 1', label: 'Informação Gerales' },
        stepTwo: { step: 'Passo 2', label: 'Configuração do processo' },
        stepThree: { step: 'Passo 3', label: 'Definições de Línguas' },
        questions: {
          stepOne: { step: 'Étape 1', label: 'Questões editoriais' },
          stepTwo: { step: 'Étape 2', label: 'Definições de Línguas' },
        },
      },
      create: {
        indicator: 'Novo SmartTest',
        title: 'Criar um novo teste SmartTest',
        subtitle: 'Novo SmartTest',
        newBlock: 'Adicionar um bloco',
        created: 'O smart test foi criado com sucesso',
        return: 'Voltar à lista de smart test',
      },
      edit: {
        viewSavedImage: 'Ver imagem guardada',
        viewImageInstructions: 'Ver imagem para instruções',
        viewImageSensors: 'Ver a imagem dos sensores',
        instructional_picture: 'Ver imagem para instruções',
        data_sensors: 'Ver a imagem dos sensores',
        indicator: 'Modificar um SmartTest',
        title: 'Modificar o smart test actual',
        subtitle: 'Modificar um SmartTest',
        created: 'O teste foi modificado com sucesso',
        return: 'Voltar à lista de smart test',
      },
      questions: {
        numberOfQuestions: 'Número de perguntas',
        indicator: 'Passos',
        title: 'Lista de passos',
        edit: {
          title: 'Edição do questionário',
          return: 'Voltar à lista de etapas',
        },
        form: {
          nameQuestionLabel: 'Título da pergunta',
          typeofQuestionLabel: 'Tipo de pergunta',
          option: 'Opção',
        },
      },
      selectionSimple: 'Selecção simples',
      selectionMultiple: 'Selecção múltipla',
      open: 'Pergunta livre',
    },
    patients: {
      anonimusFieldsDownload: 'Descarregar ficheiros anonimizados',
      return: 'Voltar à lista de pacientes',
      button: 'Adicionar um Paciente',
      steps: {
        stepOne: 'Passo 1',
        stepTwo: 'Passo 2',
      },
      labels: {
        stepOne: 'Informação ao paciente',
        stepTwo: 'Praticante de referência e pessoal médico',
      },
      create: {
        indicator: 'Nouveau patient',
        title: 'Criar um novo paciente',
        created: 'O paciente foi criado com sucesso',
      },
      edit: {
        indicator: 'Modificar o doente',
        title: 'Modificar um paciente',
        created: 'O paciente foi editado com sucesso',
      },
    },
    medicalCenters: {
      return: 'Voltar à lista de centros',
      htmlName: 'Nome do ficheiro html',
      identifiers: 'Identificadores',
      tableheader: {
        fullName: 'Nome',
        type: 'Tipo',
        email: 'Endereço electrónico',
        status: 'Estado',
        address: 'Endereço',
      },
      informationList: {
        info1: {
          title:
            'O HTML deve corresponder apenas ao corpo do documento, o cabeçalho e o rodapé serão adicionados automaticamente.',
        },
        info2: {
          title:
            'No HTML, pode incluir as seguintes etiquetas, que serão substituídas pelas informações armazenadas na nossa plataforma:',
          childs: {
            c1: 'nome completo do paciente',
            c2: 'o endereço do doente',
            c3: 'número de identificação do paciente',
            c4: 'a data de validade do documento de identificação do doente',
            c5: 'a cidade do centro médico',
            c6: 'a data em que o documento é assinado',
            c7: 'a assinatura do paciente',
          },
        },
        info3: {
          title: 'O ficheiro HTML deve ter um nome',
        },
      },
      createButton: 'Novo Centro IDP',
      show: {
        indicator: 'Ver datalle',
        titlePage: 'Ver detalhes do Centro IDP',
        informationTitle: 'Informação sobre o centro ',
        personalTitle: 'Pessoal médico associado',
        contactTitle: 'Pessoas de contacto',
        noContacts: 'Sem contactos relacionados',
        address: 'Endereço do centro',
        back: 'Return',
      },
      create: {
        titlePage: 'Criar Novo Centro',
        addressSubtitle: 'Acrescentar o endereço do centro',
        addressTitle: 'Endereço do centro',
        indicator: 'Novo centro',
        created: 'O centro foi estabelecido com sucesso',
        licenseTitle: 'Acrescentar número de licença',
        licenseSubtitle: 'Número de licença',
        informationCenter: 'Adicionar informação do centro',
        consentDocument: 'Documento de consentimento',
        confidentialDocument: 'Documento confidencial',
      },
      edit: {
        titlePage: 'Modificação de um centro',
        addressSubtitle: 'Actualização do centro médico',
        addressTitle: 'Endereço do centro',
        indicator: 'Actualização do centro',
        created: 'O centro foi modificado com sucesso e',
        informationCenter: 'Actualização de informação sobre o centro',
      },
      form: {
        name: 'Nome do centro',
        email: 'Endereço electrónico',
        phone: 'Número de telefone',
        country: 'País',
        city: 'Cidade',
        address: 'Endereço',
        typeCenter: 'Tipo de centro médico',
        license: 'Número de licença',
        updateLicense: 'Actualização do número da licença',
        nameOfTheConsentDocument: 'Nome do documento de consentimento',
        descriptionOfTheConsentDocument:
          'Descrição do documento de autorização',
      },
      contacts: {
        button: 'Acrescentar um contacto',
        title: 'Acrescentar informação de contacto',
        subtitle: 'Lista de contactos associados',
        form: {
          name: 'Nome próprio',
          lastname: 'Apelido',
          email: 'E-mail',
          phone: 'Telefone',
          fullName: 'Nome completo',
          speciality: 'Especialidade',
        },
      },
      sensors: {
        title: 'Configuração de um dispositivo SmartPredict',
        detail: 'dispositivo SmartPredict',
        kit: 'Kit',
        subtitle: 'MAC dos sensores no kit',
        button: 'Acrescentar um kit de sensores',
        form: {
          sensor: 'Sensor',
        },
      },
      biody: {
        title: "Configuração de um dispositivo Biody",
        biody: "Biody",
        titleShow: "Dispositivo Biody",
        button: "Adicionar um dispositivo",
        form: {
          id: "Dispositivo",
          license: "licença"
        }
      }
    },
    users: {
      return: 'Voltar à lista de utilizadores',
      tableheader: {
        fullName: 'Nome completo',
        rol: 'Papel',
        age: 'Idade',
        email: 'Endereço electrónico',
        verified: 'Verificado',
      },
      crateButton: 'Criar um novo utilizador',
      create: {
        titlePage: 'Criar um utilizador',
        indicator: 'Novo usuário',
        created: 'O utilizador foi criado com sucesso',
        userInfoTitle: 'Informação ao utilizador',
        userPasswordTitle: 'Criar uma palavra-passe',
      },
      edit: {
        titlePage: 'Actualizar um utilizador',
        indicator: 'Alterar o utilizador',
        created: 'O utilizador foi modificado com sucesso',
      },

      inputs: {
        name: 'Nome próprio(*)',
        lastname: 'Apelido(*)',
        phone: 'Número de telefone(*)',
        birth: 'Data de nascimento(*)',
        email: 'Endereço electrónico(*)',
        locale: 'Idioma(*)',
      },
    },

    profiles: {
      return: 'Voltar à lista de perfis',
      list: {
        button: 'Novo perfil',
      },
      create: {
        indicator: 'Novo perfil',
        title: 'Novo perfil indicador',
        step: {
          first: 'Passo 1',
          second: 'Passo 2',
          third: 'Passo 3',
        },
        stepLabel: {
          first: 'Informação Gerales',
          second: 'Configurações do perfil',
          third: 'Definições de Línguas',
        },
        nameLabel: 'Nome do perfil(*)',
        providerLabel: 'Fornecedores(*)',
        descriptionLabel: 'Descrição do perfil(*)',
        indicatorsSelection:
          'Seleccione os indicadores que deseja acrescentar a este perfil',
        indicators: 'Indicadores',
        indicatorsTitle: 'Indicadores de ligação',
        translateTitle: 'Por favor, traduza os seguintes textos',
        created: 'O perfil foi criado com sucesso',
        translateLabel: 'TEXTO ORIGINAL',
        noImage: 'Sem imagem instrutiva',
        textToTranslate: 'texto a ser traduzido',
        name: 'Nome',
        description: 'Descrição',
      },
      edit: {
        indicator: 'Actualização do perfil',
        title: 'alterar o perfil do indicador',
        edited: 'O perfil foi actualizado com sucesso',
      },
    },
    indicators: {
      return: 'Voltar à lista de indicadores',
      list: {
        button: 'Novo indicador',
      },
      create: {
        indicator: 'Novo indicador',
        title: 'Criar um novo indicador',
        step: {
          first: 'Passo 1',
          second: 'Passo 2',
          third: 'Passo 3',
        },
        stepLabel: {
          first: 'Informação Gerales',
          second: 'Anotações específicas',
          third: 'Definições de Línguas',
        },
        nameLabel: 'Nome do indicador(*)',
        descriptionLabel: 'Descrição do indicador(*)',
        dataSourceLabel: 'Fonte de dados(*)',
        frequencieLabel: 'Frequência(*)',
        recomendationLabel: 'Recomendação',
        interpretationLabel: 'Interpretação(*)',
        interpretation: 'Interpretação',
        unitLabel: 'Tipo de unidade(*)',
        valueLabel: 'Valeur',
        acronymLabel: 'Acrónimo(*)',
        type: 'Tipo de dados',
        indicatorsSelection:
          'Sélecctionnez les indicateurs que vous souhaitez ajouter á ce profil',
        indicators: 'Indicateurs',
        indicatorsTitle: 'Lier les indicateurs',
        translateTitle: 'Veullez traduire les textes sivants',
        created: 'O indicador foi criado com sucesso',
        translateLabel: 'TEXTE ORIGINAL',
        textToTranslate: 'Texte à traduire',
        name: 'Nome do indicador',
        description: 'Descrição do indicador',
      },
      edit: {
        indicator: 'Actualização de um indutor',
        title: 'Actualização de um indutor',
        edited: 'O indicador foi actualizado com sucesso',
      },
      type: {
        text: 'Texto',
        number: 'Digital',
        image: 'Imagem',
        static: 'Estático',
        boolean: 'Booleano',
      },
      annotation: {
        button: 'Acrescentar uma recomendação',
      },
    },
    questionaries: {
      return: 'Voltar à lista de questionários',
      changeButtom: 'Alterar ',
      changeStatus: 'Quer mudar o estus?',
      selectionSimple: 'Selecção simples',
      selectionMultiple: 'Selecção múltipla',
      numeric: 'Numérico',
      opened: 'Aberto',
      label: 'Etiqueta',
      option: 'Opção',
      score: 'Pontuação',
      list: {
        button: 'Novo Questionário',
        status: 'Estado',
        questionarie: 'Questionário',
      },
      create: {
        indicator: 'Novo Questionário',
        title: 'Novo Questionário',
        addOption: 'Acrescentar uma pergunta',
        step: {
          first: 'Passo 1',
          second: 'Passo 2',
          third: 'Passo 3',
        },
        stepLabel: {
          first: 'Informação Gerales',
          second: 'Perguntas',
          third: 'Definições de Línguas',
        },
        nameLabel: 'Nome do questionário(*)',
        descriptionLabel: 'Instruções para o questionário(*)',
        nameQuestionLabel: 'Título da pergunta',
        typeofQuestionLabel: 'Tipo de pergunta',
        name: 'Nome do questionário',
        description: 'Descrição',
        instructions: 'Instruções para o questionário',
        created: 'Questionário criado com sucesso',
      },
      edit: {
        indicator: 'Modificar o questionário',
        title: 'Modificar o questionário',
        created: 'Questionário modificado com sucesso',
      },
    },
    units: {
      button: 'Adicionar Unidade',
    },
    parameter: {
      button: 'Adicionar um parâmetro',
      key: 'Key',
      value: 'Valor',
      text: 'Texto',
      file: 'Ficheiro',
      json: 'Json',
    },
    typeCenter: {
      button: 'Adicionar tipo de Centro Médico',
    },
    typeStaff: {
      button: 'Acrescentar um tipo de pessoal',
      personalType: 'O tipo de pessoal será atribuído a um médico assistente?',
      code: 'Code',
      codeSystem: 'Code System',
    },
    specialities: {
      button: 'Adicionar uma Especialidade',
    },
    relationships: {
      button: 'Acrescentar uma Relação',
    },
    medicalstaff: {
      button: 'Adicionar um Pessoal Médico',
    },
    providers: {
      button: 'Adicionar um fornecedor',
    },
    healthcondition: {
      button: 'Acrescentar um estado de saúde',
    },
  },
  sidebar: {
    smartTest: 'SmartTest',
    dashboard: 'Painel de controlo',
    patients: 'Pacientes',
    groups: 'Grupos',
    mstaff: 'Pessoal médico',
    mcenter: 'Centros IDP',
    users: 'Utilizadores',
    configuration: 'Configuração',
    indicator: 'Indicador',
    questionaries: 'Questionários',
    frequencie: 'Frequências',
    provider: 'Fornecedores',
    profile: 'Perfis',
    relation: 'Relações',
    specialty: 'Especialidade',
    typestaff: 'Tipo de Pessoal',
    typecenter: 'Tipo de Centro Médico',
    unities: 'Unidades',
    healthcondition: 'Estado de saúde',
    homePredict: 'HomePredict',
    parameter: 'parâmetro',
  },
  breadcrumb: {
    smartTest: 'SmartTest',
    dashboard: 'Painel de controlo',
    units: 'Unidades',
    patient: 'Paciente',
    groupsPatient: 'Grupos de pacientes',
    groupNewAssociate: 'Novo Grupo - Associado',
    mcenter: 'Centro médico',
    centerIDP: 'Centro IDP',
    typeStaff: 'Tipo de pessoal',
    medicalStaff: 'Pessoal médico',
    newPatient: 'Novo paciente',
    newStaff: 'Novo pessoal médico',
    detailStaff: 'Detalhe do pessoal médico',
    typeMedicalCenter: 'Tipo de centro médico',
    newcenterIDP: 'Novo Centro IDP',
    specialties: 'Especialidade',
    relationships: 'Relações',
    providers: 'Fornecedores',
    healthConditions: 'Estado de saúde',
    healthCondition: 'Estado de saúde',
    frequencies: 'Frequências',
    questionaries: 'Questioários',
    newQuestionaries: 'Novo Questionário',
    detailQuestionarie: 'Detalhe do Questionário',
    indicators: 'Indicador',
    newIndicators: 'Novo indicador',
    detailIndicators: 'Detalhe do indicador',
    profiles: 'Perfil',
    newProfiles: 'Novo perfil',
    detailProfiles: 'Detalhe do perfil',
    editIndicatorsProfiles: 'Adicionar/eliminar indicadores',
    associateQuestionariesProfiles: 'Questionário de Associação',
    users: 'Utilizador',
    newUser: 'Novo utilizador',
    detailUser: 'Detalhe do utilizador',
    add: 'Adicionar',
    edit: 'Editor',
    detail: 'Detalhe',
    homePredict: 'HomePredict',
    parameter: 'parâmetro',
  },
  form: {
    fieldEmail: 'Email',
    fieldPassword: 'Palavra-passe',
    fieldConfirmPassword: 'Confirmar palavra-passe',
    fieldValidationPasswords: 'As palavras-passe não são as mesmas',
    fieldValidationSecuritypasswords:
      'As senhas não estão de acordo com as políticas de segurança',
    button: {
      actionSave: 'Guardar',
    },
    searchBar: 'Digite o texto de pesquisa',
  },
  auth: {
    name: "'IDP Sante'",
    titleLogin: 'S’identifier',
    authTwoFactor: 'Autenticação de dois factores',
    authTwilio: 'Autenticação por SMS',
    placeholderCode: 'Introduza o código',
    placeholderUsername: 'Nome de utilizador ou email',
    placeholderPassword: 'Password',
    placeholderForgetPassword: 'Endereço de correio',
    forgetPassSubTitle: 'Esqueceu a password?',
    forgetPassDescription:
      'Indique o seu email e enviaremos um link de recuperação.',
    invalidData: 'credenciais inválidas',
    invalidcenter:
      'Actualmente não pode iniciar sessão, por favor contacte o seu administrador.',
    invalidCode: 'Código do Google inválido',
    invalidSmsCode: 'Código SMS inválido',
    codeWasSend: 'Foi enviado um novo código por SMS',
    codeWasNotSend:
      'Ocorreu um erro ao enviar o código, tente novamente mais tarde.',
    sendRequest: 'Enviar pedido',
    reutnToLogin: 'Voltar ao início de sessão',
    cancelRequest: 'Cancelar pedido',
    resetPassSubTitle: 'Recuperar password',
    loginMethod: 'Método de início de sessão',
    loginMethodSelected:
      'Seleccionar o método a utilizar para a autenticação da conta',
    phoneNumber: 'Número de telefone',
    resetPassDescription: 'Introduza uma nova password e confirme.',
    enterValidatePhoneNumber:
      'Introduza um número de telefone válido para lhe enviar o código de autenticação.',
    resetPassSave: 'Guardar',
    signInButton: 'Entrar',
    tryAgain: 'Tentar novamente',
    resendCode: 'Reenviar código',
    changeNumber: 'Alterar o número de telefone',
    signInForgotPass: 'Esqueceu a password?',
    signUpTermsConditions: 'Aceito os termos e condições',
    signUpButton: 'Registar-se',
    signUpAlreadyAccount: 'Já tem uma conta? Entrar.',
    textNeedChangePassword:
      'A sua palavra-passe expirou. Por razões de segurança, é necessário alterar a sua palavra-passe.',
    textLinkChangePassword: 'Alterar Palavra-passe',
    textGenerateNewPassword:
      'Para continuar, foi enviado um link para o seu endereço electrónico para gerar a palavra-passe.',
    textOne2Factor:
      'Digitalize o QRcode com a aplicação Google Authenticator no seu telefone.',
    textTwo2Factor:
      'O código do Google Authenticator para IDP SANTE aparece na lista.',
    textTree2Factor:
      'Se não conseguir digitalizar o código, pode introduzir manualmente a chave de configuração na aplicação Google Authenticator:',
    textFour2Factor:
      'Para as ligações seguintes, pode utilizar directamente os códigos gerados pelo Google Authenticator.',
    textEmailSend: 'Enviar',
    textEmailReSend: 'Reenviar',
    textEmailCancel: 'Cancelar',
    textAttention: 'Atenção',
    textSliderPasswordWeak: 'Fraco',
    textSliderPasswordStrong: 'Forte',
    textSecurityPoliciesTitle: 'A senha deve conter',
    textSecurityPoliciesRule1: '- 8 ou mais caracteres',
    textSecurityPoliciesRule2:
      '- Pelo menos uma letra maiúscula e uma letra minúscula',
    textSecurityPoliciesRule3:
      '- Pelo menos um número e um carácter especial permitido @$!%*?&',
    buttonTextBackLogin: 'Voltar para a página de login',
    sendLinkSuccess: 'link enviado para email',
    alert: 'Aviso',
    oldPasswordAlert:
      'Senha inválida. Deve ser diferente dos últimos três usados ​​anteriormente.',
    resetPassSuccess: 'Uma restauração bem-sucedida',
    SMS: 'SMS',
    authenticator: 'Autenticador do Google',
  },
  homePredict: {
    title: 'HomePredict',
    errorUpdatePatient: 'O paciente não pode ser actualizado neste momento',
    errorUpdatePatients: 'Actualmente não é possível actualizar os pacientes',
  },
  medicalstaff: {
    add: 'Adicionar equipe médica',
    edit: 'Editor da equipe médica',
    editShort: 'Edição',
    save: 'Salvar',
    back: 'Return',
    personalInformation: 'Informações sobre o tipo de pessoal',
    treatingPhysician: 'Médico de tratamento',
    tableheader: {
      fullName: 'Nome completo',
      type: 'Tipo',
      address: 'Endereço',
      specialty: 'Especialidade',
      email: 'endereço de e-mail',
      centersIDP: 'Centros IDP',
      status: 'Status',
    },
    form: {
      return: 'Voltar à lista de pessoal médico',
      created: 'O pessoal médico foi criado com sucesso',
      edited: 'O pessoal médico foi modificado com sucesso',
      first_name: 'Primeiro nome',
      last_name: 'Sobrenome',
      date_of_birth: 'Data de nascimento',
      gender: 'gênero',
      mail_address: 'Endereço de correio',
      phone_number: 'Número de telefone',
      medical_center: 'Centro médico',
      staff_type: 'Tipo de equipe',
      speciality: 'Especialidade',
      age: 'Idade',
      codeInsee: 'Lugar de nascimento',
      code: 'Code',
      codeSystem: 'Code System',
      rpps: 'RPPS',
    },
    show: {
      personalInfo: 'Informação pessoal',
      professionalInfo: 'Informação profissional',
      male: 'Homme',
      female: 'Femme',
    },
  },
  patient: {
    title: 'Lista geral de pacientes',
    teleExpertise: {
      selectAll: 'Selecionar tudo',
      unselectAll: 'Desmarcar tudo',
      submit: 'Enviar pedido',
      request: {
        success: 'A candidatura foi apresentada com êxito',
        error: 'Ocorreu um erro ao enviar o pedido',
      },
      youHave: 'Tem',
      selectedFiles: 'ficheiros seleccionados',
      reason: {
        title: 'Motivo do pedido',
      },
      selects: {
        title: 'Seleccione os ficheiros que pretende anexar',
        smartPredict: 'Relatório smartpredict',
        right_ear: 'Imagens da orelha direita',
        left_ear: 'Imagens do ouvido esquerdo',
        extended: 'Áudio Coração / Pulmões',
        lungs: 'Áudio Pulmões',
        heart: 'Áudio Coração',
        vdsi: 'Relatório VDSI',
      },
    },
    tableheader: {
      type: 'Tipo',
      speciality: 'Especialidade',
      fullName: 'Nome completo',
      name: 'Nome',
      gender: 'Sexo',
      age: 'Idade',
      fragilityScore: 'Pontuação de fragilidade',
      fragility: 'Data da próxima reunião',
      stability: 'Pontuação de estabilidade',
      status: 'Status',
      vdsi: 'SLIMS',
    },
    form: {
      heart: 'Coração',
      lungs: 'Pulmão',
      extended: 'Coração/Pulmão',
      takePhoto: 'Tira uma foto',
      name: 'Primeiro nome (*)',
      validate_to: 'Válido até',
      observation: 'Observações',
      lastname: 'Último nome (*)',
      birth: 'Data de nascimento (*)',
      sex: 'Gênero (*)',
      left_ear: 'Orelha esquerda',
      right_ear: 'Orelha direita',
      gender: {
        female: 'Feminino',
        male: 'Homem',
      },
      hands: {
        right: 'Direito',
        left: 'Canhoto',
      },
      height: 'Tamanho cm (*)',
      weight: 'Peso Kg (*)',
      right_hand: 'Principal (*)',
      place_birth: 'País nativo (*)',
      address: 'Endereço (*)',
      email: 'Endereço de correio (*)',
      phone: 'Número de telefone (*)',
      SSN: 'Número da Segurança Social',
      zipCode: 'Código postal',
      center_id: 'Centro médico (*)',
      health: 'Saúde',
      codeInsee: 'Code Insee',
      date: 'Filtrar por data',
      searchFilter: 'Filtros de pesquisa',
      startDate: 'Data de início',
      endDate: 'Data final',
      clearDate: 'Limpar filtros',
      department: 'Departamentos',
      community: 'Comunidades',
      cc: 'CC - Nº do Cartão de Cidadão',
      nif: 'NIF - Nº de Identificação Fiscal',
      niss: 'NISS - Nº Segurança Social',
      sns: 'SNS - Nº de Utente do Serviço Nacional de Saúde',
      comments: 'Informações adicionais',
      dni: 'DNI - Cartão de identidade nacional',
      nuss: 'NUSS - Número de Segurança Social',
    },
    hands: {
      right: 'Mão direita',
      left: 'Mão esquerda',
    },
    show: {
      personalInfo: 'Informação pessoal',
      contactInfo: 'Informação de contato',
      healthInfo: 'Condições sanitárias',
      medicalInfo: 'Pessoal médico',
      principalDoctor: 'Médico de referência',
      documents: 'Documentos jurídicos',
      observation: 'Observações',
      add: 'adicionar',
      update: 'actualização',
    },
    patientInfo: {
      email: 'Endereço de correio',
      phone: 'Número de telefone',
      birth: 'Data de nascimento',
      address: 'Endereço',
      country: 'País',
      hand: 'Mão dominante',
      height: 'Tamanho',
      weight: 'Peso',
      SSN: 'Número da Segurança Social',
      male: 'Homem',
      female: 'Feminino',
      medicalCenter: 'Centro médico parceiro',
      sex: 'Gênero',
      department: 'Departamento',
      community: 'Comunidade',
      cc: 'Cartão de Cidadão',
      nif: 'Identificação Fiscal',
      niss: 'Segurança Social',
      sns: 'Serviço Nacional de Saúde',
      comments: 'Informação adicional',
      dni: 'Cartão de identidade nacional',
      nuss: 'Número de Segurança Social',
    },
    detail: {
      document: {
        firmdate: 'Data de assinatura',
        description: 'Descrição',
        sendDocument: 'Introduza o endereço de correio electrónico  ',
        success: 'Os documentos legais foram enviados com sucesso',
        error: 'Houve um problema no envio dos documentos legais ',
        view: 'Ver Documento Guardado',
        send: 'Enviar o documento',
        accepted: 'Aceite',
        declined: 'Recusado',
      },
      pdf: {
        description:
          'Adicionar os endereços de correio electrónico separados por vírgulas.',
        success: 'O relatório SmartPredict foi apresentado com sucesso',
        errorSend: 'Ocorreu um erro ao enviar o relatório SmartPredict. ',
      },
      tugInformation: 'TUG Informação',
      BilanTug: 'Bilan TUG',
      subTabs: {
        tabGraphique: 'Gráfico',
        tabIndicators: 'Indicadores',
        tabBalance: 'Balanço',
      },
      tabObservations: {
        observation: 'Observações',
        allDates: 'Todas as datas',
      },
      tabSmartPredict: {
        cardTab: {
          description: 'Descrição',
          interpretation: 'Interpretação',
          recomendation: 'recomendação',
        },
        stadistic: {
          fragilite: {
            bad: 'Frágil',
            stable: "Pre'frágil",
            good: 'Robusto',
          },
          equilibre: {
            bad: 'Instável',
            good: 'Estável',
          },
          risqueChute: {
            bad: 'Alto',
            good: 'Baixo',
          },
        },
        fragility: 'Fragilidade',
        marcha: 'Marcha',
        psychological: 'Pontuações',
        physiologique: 'Avaliação Physio',
        name: 'SmartPredict',
        dataForDate: 'Visualização de dados de',
        impedance: "Bioimpedância",
        pdf: {
          sentPdf: 'Enviar PDF',
          downloadPdf: 'Descarregar PDF',
          documentOption: `Opções de documentos`,
          IheXdmReport: `Relatório IHE XDM`,
          smartPredictReport: `Relatório SmartPredict`,
          seePdf: `Ver PDF`,
        },
        firtsIndicators: {
          time_TUG: 'Tempo TUG',
          walk_aller_plus_retour: 'Tempo de funcionamento',
          number_of_steps_aller_retour: 'Número de passos',
          risque_TUG: 'Alto risco de queda',
        },
        graphic: {
          labelDates: 'Datas',
          labelValue: 'Valor',
          labelLoadingText: 'Carregamento...',
        },
      },
      tabInformation: {
        name: 'Informação geral',
        openEyes: 'Olhos abertos',
        closeEyes: 'Olhos fechados',
        notInformation: 'Não há indicadores disponíveis',
        mainDoctor: 'Médico atendente',
        years: 'anos',
        vdsi: 'Bilans VDSI',
        teleExpertise: 'Teleconhecimento',
      },
      tabSmartDetect: {
        tab: 'SmartDetect',
        openEyes: 'Olhos abertos',
        closeEyes: 'Olhos fechados',
        cardsIndicators: {
          pressionForce: 'Força de aderência',
          maxLeft: 'Esquerda máxima',
          maxRight: 'Direito máximo',
          WeightDistribution: 'Distribuição do peso',
        },
      },
      tabTip: {
        tips: 'Dicas',
        enterTheSearchText: 'Introduzir o texto de pesquisa',
        noResults: 'Sem resultados',
        simple: 'Conselho único',
        pack: 'Pacote de conselhos',
        specialty: 'Especialidade',
        configurationList: 'Lista de configurações ',
        programmingWasSuccessfullyCreated:
          'A programação foi criada com sucesso ',
        modal: {
          username: 'Nome de utilizador',
          password: 'Palavra-passe',
          login: 'Iniciar sessão Certipair',
          incorrectPasswordUsernamePlease:
            'Palavra-passe e/ou nome de utilizador incorrectos, volte a iniciar sessão',
        },
        form: {
          now: 'Agora',
          certifyThatHaveObtained:
            'Certifico que obtive o consentimento do paciente para enviar mensagens de saúde por SMS através da aplicação CertiPair.',
        },
        buttons: {
          hideAllMessages: 'Ocultar todas as mensagens',
          viewMessages: 'Ver mensagens',
        },
        tabOne: {
          adviceAssociatedWithThisPatient: 'Conselhos associados a este doente',

          newCouncil: 'Novo Conselho',
          message: 'Mensagem',
          status: 'Estado',
          startDate: 'Data de criação ',
          endDate: 'Data de expedição',
          sent: 'Enviado',
          canceled: 'Cancelado',
        },
        tapTwo: {
          and: 'e',
          hours: 'horas',
          deliveryTime: 'Prazo de entrega',
          packageDetails: 'Detalhes da embalagem',
          messagesIn: 'mensagens em',
          days: 'dias',
          months: 'Meses',
          webLink: 'Ligação Web',
          selectTheMessages: 'Seleccionar as mensagens',
          all: 'Todos',
          myFavorites: 'Os meus favoritos',
          professions: 'Profissões',
          noAdviceFound: 'Nenhum conselho encontrado',
          tip: 'Conselho',
          attachment: 'Anexo',
          whenDoYouWantToSendThisMessage:
            'Quando é que pretende enviar esta mensagem?',
          selectDate: 'Seleccionar uma data',
          selectTime: 'Seleccionar uma hora',
          schedule: 'Horário',
          backToListOfMessages: 'Voltar à lista de mensagens',
        },
      },
    },
    back: 'Retour',
    next: 'Seguinte',
    finish: 'Terminar',
  },
  validators: {
    pdfIsNotValidate: 'A versão PDF não é suportada',
    zipCode: 'O código postal não pertence a nenhuma comunidade',
    zipCodeNotAssociate:
      'Não foi encontrado nenhum código postal associado ao departamento',
    invalidZipCode: 'O código postal não é válido',
    emplyEmail: 'e-mail e requerido',
    emplyPassword: 'Senha requerida',
    emplyCodeGoogle: 'o código do google é obrigatório',
    invalidMacAddress: 'Endereço MAC inválido',
    invalidEmail: 'Formato de email inválido',
    emailRegistered: 'O correio já está registado',
    required: 'Campo obrigatório',
    jsonIsNotValidate: "O formato JSON não é válido",
    number: 'O campo deve ser numérico',
    emailExist: 'Correio já existe',
    phoneExist: 'O telefone já existe',
    addressExist: 'O endereço já existe',
    invalidPhone: 'Introduzir um número de telefone válido',
    invalidHeight: 'A altura deve ser entre 1 e 999 Cm',
    invalidWeight: 'O peso deve estar entre 1 e 999 Kg',
    license: 'A licença é inválida',
    name: 'O nome é inválido',
    lastname: 'O apelido é inválido',
    image: 'Formato de imagem inválido',
    minLength: 'O campo deve conter',
    num: 'números',
    characters: 'Caracteres',
    imageSize: 'O peso máximo da imagem deve ser de 1MB.',
    invalidFormat: 'O formato não é válido',
    undefinedLatitudeAndLongitude: 'Latitude e longitude indefinidas ',
  },
  alt: {
    logoIdp: 'logotipo IDP',
    welcomeIdp: 'bem-vindo IDP',
  },
  languages: {
    french: 'Francês',
    portuguese: 'Português',
    spanish: 'Espanhol',
    english: 'Inglês',
    spanishCo: "Colombiano"
  },
}

export default pt

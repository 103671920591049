export const extraFieldPt = ['cc', 'nif', 'niss', 'sns', 'dni', 'nuss']

export const ItemMenu = {
    name: true,
    // lastName: true,
    birthday: true,
    sex: true,
    address: true,
    phone: true,
    score: true,
    dateEnd: true,
    medical_staff: true,
    value: true
}
